<template>
  <v-modal :show.sync="value" :on-close="close">
    <v-modal-header :title="$t('_word.add_coupon')" :on-close="close" />

    <v-modal-body>
      <p>{{ $t('_word.add_coupon_info') }}</p>

      <br>

      <v-input
          :label="$t('_field.coupon_code')"
          :required="true"
          :error="$v.code"
          v-model="code"
      />

      <br>

      <v-button :label="$t('_button.add_coupon')" align="full" :click="add" :spinning="isBusy" />
    </v-modal-body>
  </v-modal>
</template>

<script>
import VModal from "@/components/modal/Modal";
import VModalHeader from "@/components/modal/ModalHeader";
import VModalBody from "@/components/modal/ModalBody";
import VDropdown from "@/components/form/Dropdown";
import VInput from "@/components/form/Input";
import VButton from "@/components/form/Button";
import publicApi from "@/api/public";
import {email, maxLength, required} from "vuelidate/lib/validators";
import Vue from "vue";

function values() {
  return {
    code: '',
    isBusy: false
  };
}

export default {
  components: {
    VButton,
    VInput,
    VDropdown,
    VModalBody,
    VModalHeader,
    VModal,
  },

  props: {
    value: { required: true },
    room: {
      type: Object,
    },
    reservation: {
      type: Object,
    },
    next: {
      type: Function
    }
  },

  data() {
    return values();
  },

  validations: {
    code: {
      required
    },
  },

  methods: {
    close() {
      this.$emit("input", false);
      setTimeout(() => {
        Object.assign(this.$data, values());
        this.$v.$reset();
      }, 300);
    },
    add() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$popcorn("error", this.$t("_error.not_all_fields_are_correct"));
        return;
      }

      this.isBusy = true;

      publicApi.post("check-coupon", {code: this.code}).then((res) => {

        Vue.set(this.reservation, 'coupon', res.data);

        this.$popcorn('success', this.$t('_word.coupon_added', {code: this.code}));
        this.close();
      }).catch(() => {

        this.$popcorn('error', this.$t('_error.coupon_does_not_exists'));
        this.isBusy = false;
      });
    }
  },
};
</script>
