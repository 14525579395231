<template>
  <div class="holder">
    <div class="empty" v-if="rooms == null">
      <v-spinner :size="100" />
      <h2 class="empty-title">{{ $t("_word.loading_availability") }}</h2>
    </div>
    <div class="inner" v-else>
      <div v-if="availableRooms.length > 0">
        <h2 class="title">{{ $t("_word.choose_room") }}</h2>
        <div
          class="date"
          @click="
            () => {
              goStep(1);
            }
          "
        >
          <span class="material-icons">calendar_month</span>
          <div class="text">
            {{
              formatDate(reservation.start_date) +
              " - " +
              formatDate(reservation.end_date)
            }}
          </div>
        </div>

        <div class="rooms">
          <div class="room" v-for="room in availableRooms" :key="room.id">
            <img
              :src="
                room.images.length == 0
                  ? 'assets/default.jpg'
                  : room.images[0].url
              "
            />

            <div class="info">
              <h3>{{ room.name }}</h3>

              <div class="persons">
                <span class="material-icons">person_outline</span>
                {{
                  $tc("_word.x_persons", room.max_persons, {
                    x: room.max_persons,
                  })
                }}
              </div>

              <div class="description">
                <p v-html="room.description[$i18n.locale]"></p>
              </div>

              <div class="price">
                <div class="price-from">
                  {{ $t("_word.starting_from") }}
                </div>
                <div class="price-amount">
                  <div class="price-unit">
                    {{ formatPrice(room.price_per_night) }}
                  </div>
                  <div class="price-text">{{ $t("_word.per_night") }}</div>
                </div>
              </div>

              <v-button
                :click="
                  () => {
                    showRoomModal = true;
                    selectedRoom = room;
                  }
                "
                align="full"
                :icon-before="false"
                :label="
                  reservation.rooms.find((it) => {
                    return it.id == room.id;
                  }) == null
                    ? $t('_button.select')
                    : $t('_button.selected')
                "
                :icon="
                  reservation.rooms.find((it) => {
                    return it.id == room.id;
                  }) == null
                    ? 'east'
                    : 'check'
                "
              />
            </div>
          </div>
        </div>
      </div>
      <div v-else class="no-availability">
        <h2 class="title">{{ $t("_word.no_rooms_available") }}</h2>
        <p>{{ $t("_word.change_the_date_to_view_other_options") }}</p>

        <v-button
          :click="
            () => {
              goStep(1);
            }
          "
          :label="$t('_button.back_to_dates')"
        />
      </div>

      <div class="unavailable-rooms" v-if="unavailableRooms.length > 0">
        <h2 class="title">{{ $t("_word.rooms_without_availability") }}</h2>

        <div class="rooms">
          <div
            class="room unavailable"
            v-for="room in unavailableRooms"
            :key="room.id"
          >
            <img
              :src="
                room.images.length == 0
                  ? 'assets/default.jpg'
                  : room.images[0].url
              "
            />

            <div class="info">
              <h3>{{ room.name }}</h3>

              <div class="persons">
                <span class="material-icons">person_outline</span>
                {{
                  $tc("_word.x_persons", room.max_persons, {
                    x: room.max_persons,
                  })
                }}
              </div>

              <div class="description">
                <p v-html="room.description[$i18n.locale]"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <room-modal
      :reservation="reservation"
      :room="selectedRoom"
      :next="next"
      v-model="showRoomModal"
    />
  </div>
</template>

<script>
import VSpinner from "@/components/global/Spinner";
import VButton from "@/components/form/Button";
import RoomModal from "@/components/engine/modal/Room";

export default {
  components: {
    RoomModal,
    VButton,
    VSpinner,
  },

  props: {
    activeStep: {},
    next: {
      type: Function,
    },
    reservation: {
      type: Object,
    },
    rooms: {},
    goStep: {
      type: Function,
    },
  },

  data() {
    return {
      showRoomModal: false,
      selectedRoom: null
    };
  },

  computed: {
    availableRooms() {
      return this.rooms.filter((room) => {
        return room.max_availability > 0;
      });
    },
    unavailableRooms() {
      return this.rooms.filter((room) => {
        return room.max_availability === 0;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.holder {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  justify-content: center;
  box-sizing: border-box;
  overflow: auto;

  .empty {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;

    .empty-title {
      margin-top: 20px;
      font-size: 23px;
    }
  }

  .inner {
    padding: 50px 0 100px 0;
    height: fit-content;

    .title {
      font-size: 27px;
      margin-bottom: 6px;
      text-align: center;
    }

    .date {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 8px 20px;
      width: fit-content;
      margin: 0 auto 30px auto;
      border-radius: 30px;

      span {
        color: #888;
        margin-right: 10px;
        font-size: 17px;
        top: 1px;
        position: relative;
        display: flex;
      }

      .text {
        color: #888;
        font-size: 15px;
        font-weight: 500;
        display: flex;
      }

      &:hover {
        background: #f3f3f3;
      }
    }

    .no-availability {
      height: 350px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .title {
        margin-bottom: 15px;
      }
      p {
        font-size: 18px;
        margin: 0 0 40px 0;
        color: #888;
      }
    }

    .rooms {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 30px;
      width: 1200px;
      padding: 0 40px;
      box-sizing: border-box;
      margin: 0 auto;

      .room {
        width: 450px;
        z-index: 2;
        background: var(--card-background-color);
        border-radius: var(--card-border-radius);
        box-shadow: var(--card-box-shadow);
        padding: 15px;

        img {
          width: 100%;
          height: 280px;
          object-fit: cover;
          border-radius: 8px;
        }

        .info {
          padding: 0 15px 15px 15px;

          h3 {
            font-weight: 500;
            font-size: 22px;
            margin-bottom: 7px;
          }

          .persons {
            font-size: 17px;
            display: flex;
            margin-left: -4px;
            align-items: center;
            color: #888;

            span {
              color: var(--primary-color);
              margin-right: 10px;
              font-size: 27px;
            }
          }

          .description {
            margin: 20px 0;

            p {
              font-size: 16px;
            }
          }

          .price {
            margin-top: 20px;
            margin-bottom: 30px;

            .price-from {
              font-size: 15px;
              color: #888;
              margin-bottom: 4px;
            }

            .price-amount {
              display: flex;
              align-items: flex-end;

              .price-unit {
                font-size: 24px;
                font-weight: 500;
              }

              .price-text {
                margin-left: 13px;
              }
            }
          }
        }

        &.unavailable {
          opacity: 0.5;
        }
      }

      @media only screen and (max-width: 1300px) {

        & {
          width: 100%;
        }
      }

      @media only screen and (max-width: 500px) {

        & {
          padding: 0 20px;

          .room {
            width: 100%;

            img {
              height: unset;
              aspect-ratio: 1.5;
            }
          }
        }
      }
    }

    .unavailable-rooms {
      margin-top: 100px;

      .title {
        margin-bottom: 40px;
      }
    }
  }
}
</style>
