<template>
  <label class="checkbox-holder"
    >
    <div class="checkbox-label" v-html="label" />
    <div class="error-message" v-if="error.$error">
      {{ $t("_error.required_short") }}
    </div>
    <input
      :type="type"
      :value="val"
      :true-value="trueValue"
      :false-value="falseValue"
      v-model="checked"
      @change="change"
    />
    <span
      class="checkmark"
      :class="{ error: error.$error, radio: type === 'radio' }"
    />
  </label>
</template>

<script>
export default {
  name: "VCheckbox",

  data() {
    return {
      checkedProxy: false,
    };
  },

  props: {
    type: {
      type: String,
      default: "checkbox",
      validator(value) {
        return ["checkbox", "radio"].includes(value);
      },
    },
    label: {
      default: null,
      type: String,
    },
    value: {
      required: true
    },
    trueValue: {
      default: true,
    },
    falseValue: {
      default: false,
    },
    val: {
      default: "yes",
    },
    error: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  computed: {
    checked: {
      get() {
        return this.value;
      },
      set(val) {
        this.checkedProxy = val;
      },
    },
  },

  methods: {
    change(e) {
      this.$emit("input", this.checkedProxy);
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox-holder {
  position: relative;
  padding-left: 39px;
  margin-bottom: 15px;
  padding-top: 4px;
  min-height: 26px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: var(--text-font-weight);
  color: #888;
  font-size: var(--text-font-size);

  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    left: 0;
    height: 23px;
    width: 23px;
    border-style: solid;
    border-color: var(--form-field-border-color);
    border-width: var(--form-field-border-width);
    box-shadow: var(--form-field-box-shadow);
    background: var(--form-field-background-color);
    border-radius: 5px;

    &.error {
      border-color: var(--form-field-error-border-color);
      background: var(--form-field-error-background-color);
    }

    &.radio {
      border-radius: 20px;
    }
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 8px;
    top: 1px;
    width: 6px;
    height: 14px;
    border: solid var(--primary-color);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .checkmark.radio:after {
    left: 8px;
    top: 2px;
    width: 5px;
    height: 12px;
    border: solid var(--primary-color);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .error-message {
    color: var(--form-field-error-color);
    letter-spacing: var(--form-field-error-letter-spacing);
    font-size: var(--form-field-error-font-size);
    font-weight: var(--form-field-error-font-weight);
    text-align: left;
    margin-bottom: -14px;
    margin-top: 0;
  }
}
</style>
