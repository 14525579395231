import axios from "axios";
import Vue from 'vue'
import app from "../main";

export const publicApi = axios.create({});

publicApi.interceptors.request.use((request) => {
    request.headers.common["Accept-Language"] = app.$i18n.locale;

    request.baseURL = Vue.config.api_url;

    return request;
});

publicApi.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response) {
            const { status } = error.response;

            if (status >= 500) {
                app.$popcorn(
                    "error",
                    app.$t("_error.something_went_wrong_please_try_again")
                );
            }

            if (status === 422) {
                if (error.response) {
                    for (const id in error.response.data.errors) {
                        app.$popcorn("error", error.response.data.errors[id][0]);
                    }
                }
            }
        }
        return Promise.reject(error);
    }
);

export default publicApi;
