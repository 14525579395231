const merge = require("lodash.merge");

export default (base, files, fileType = "json", getDefault = false) => {
  if (window[base] == null) window[base] = {};

  files.keys().forEach((file) => {
    const fileName = file.replace("./", "").replace("." + fileType, "");
    if (window[base][fileName] == null) window[base][fileName] = {};

    let fileContent = files(file);
    if(getDefault) fileContent = fileContent.default;

    window[base][fileName] = merge(window[base][fileName], fileContent);
  });
};
