<template>
  <div
    class="body"
    :class="{ scroll: scroll }"
    :style="scroll ? 'max-height: ' + height : ''"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "VModalBody",

  props: {
    scroll: {
      type: Boolean,
      default: false,
    },
    hasLargeHeader: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    height() {
      if (!this.scroll) return "";

      let pixels = 0;
      if (this.hasLargeHeader) {
        pixels += 223;
      }

      return "calc(90vh - " + pixels + "px)";
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  padding: 20px 50px 38px 50px;

  &.scroll {
    display: flex;
    overflow: auto;
    flex-direction: column;
  }

  @media only screen and (max-width: 500px) {
    & {
      padding: 20px 25px 30px 25px;
    }
  }
}
</style>
