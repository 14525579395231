<template>
  <div class="booking-holder">
    <top-navigation :active-step="activeStep" :last-step="lastStep" :go-step="goStep" />

    <div class="loading-holder" v-if="isLoading">
      <div class="loading">
        <v-spinner :size="100" />
        <h2 class="loading-title">{{ $t("_word.loading") }}</h2>
      </div>
    </div>

    <div v-if="!isLoading" v-frag>

      <date-screen
        :active-step="activeStep"
        v-show="activeStep == 1"
        :reservation="reservation"
        :next="dateNext"
      />

      <rooms-screen
        :active-step="activeStep"
        v-show="activeStep == 2"
        :rooms="rooms"
        :goStep="goStep"
        :reservation="reservation"
        :next="roomNext"
      />

      <summary-screen
        :active-step="activeStep"
        v-show="activeStep == 3"
        :rooms="rooms"
        :goStep="goStep"
        :reservation="reservation"
        :next="summaryNext"
      />

      <details-screen
        :active-step="activeStep"
        v-show="activeStep == 4"
        :goStep="goStep"
        :reservation="reservation"
        :next="summaryNext"
      />
    </div>

    <portal-target name="modals" multiple />
  </div>
</template>

<script>
import TopNavigation from "@/components/engine/TopNavigation";
import DateScreen from "@/components/engine/DateScreen";
import publicApi from "@/api/public";
import RoomsScreen from "@/components/engine/RoomsScreen";
import SummaryScreen from "@/components/engine/SummaryScreen";
import DetailsScreen from "@/components/engine/DetailsScreen";
import VSpinner from "@/components/global/Spinner";
import Vue from "vue";

export default {
  components: {
    VSpinner,
    DetailsScreen,
    SummaryScreen,
    RoomsScreen,
    DateScreen,
    TopNavigation
  },

  data() {
    return {
      isLoading: false,

      activeStep: 1,
      lastStep: 1,
      rooms: null,
      reservation: {
        rooms: [],
        start_date: null,
        end_date: null,
        first_name: "",
        last_name: "",
        email: "",
        mobile_phone: "+31 6",
        nationality: "",
        remarks: "",
        terms: false
      },
    };
  },

  methods: {
    goStep(step) {
      if (step <= this.lastStep) {
        this.activeStep = step;
      }
    },

    dateNext(fast = false) {
      this.rooms = null;

      publicApi
        .post("availability", {
          start_date: this.reservation.start_date,
          end_date: this.reservation.end_date,
        })
        .then((res) => {
          this.reservation.rooms = [];
          this.rooms = res.data;
        });

      setTimeout(() => {
        this.activeStep = 2;
        this.lastStep = 2;
      }, fast ? 0 : 700);
    },

    roomNext() {
      this.activeStep = 3;
      if(this.lastStep < 3) this.lastStep = 3;
    },

    summaryNext() {
      this.activeStep = 4;
      if(this.lastStep < 4) this.lastStep = 4;
    },
  },

  created() {

    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    if(params.start_date && params.end_date) {

      this.reservation.start_date = params.start_date;
      this.reservation.end_date = params.end_date;

      this.dateNext(true);
    }


    if(params.type === 'callback' && params.id != null && params.token != null) {

      this.activeStep = 4;
      this.lastStep = 4;
      this.isLoading = true;

      let id = params.id;
      let token = params.token;

      publicApi.get("reservation/" + id + "/" + token, this.reservation).then((res) => {

        if(res.data.status === 'success') {
          document.location.href = 'https://slapendehollander.nl/bedankt-voor-uw-reservering?first_name=' + encodeURI(res.data.first_name) + '&period=' + encodeURI(res.data.start_date + ' tot ' + res.data.end_date)
          return;
        }

        publicApi
            .post("availability", {
              start_date: res.data.start_date,
              end_date: res.data.end_date,
            })
            .then((res2) => {

              this.rooms = res2.data;


              this.reservation.start_date = res.data.start_date;
              this.reservation.end_date = res.data.end_date;
              this.reservation.first_name = res.data.first_name;
              this.reservation.last_name = res.data.last_name;
              this.reservation.email = res.data.email;
              this.reservation.mobile_phone = res.data.mobile_phone;
              this.reservation.nationality = res.data.nationality;
              this.reservation.remarks = res.data.remarks;
              this.reservation.terms = true;
              Vue.set(this.reservation, 'coupon', res.data.coupon);

              res.data.rooms.forEach((room) => {

                let roomData = this.rooms.find((roomEx) => { return roomEx.id === room.id});

                this.reservation.rooms.push({
                  id: room.id,
                  number_of_guests: room.number_of_guests,
                  number_of_rooms: room.number_of_rooms,
                  total_price: roomData.total_price,
                  data: roomData
                })
              });

              this.isLoading = false;
            });
      });
    }



  }
};

</script>

<style lang="scss" scoped>
.booking-holder {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;

  .loading-holder {
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
    justify-content: center;
    box-sizing: border-box;
    overflow: auto;

    .loading {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;

      .loading-title {
        margin-top: 20px;
        font-size: 23px;
      }
    }
  }
}
</style>
