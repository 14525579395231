import PopCorn from "../components/popcorn/PopCorn";

export default {
  install(Vue) {
    const POPCORN_ELEMENT = Vue.extend(PopCorn);
    let POPCORN = null;

    function popcorn(type, text) {
      if (POPCORN == null) {
        POPCORN = new POPCORN_ELEMENT();
        let vm = POPCORN.$mount();

        const para = document.createElement("div");
        para.classList = "popcorn-portal";
        document.querySelector("body").appendChild(para);

        document.querySelector("body .popcorn-portal").appendChild(vm.$el);
      }
      POPCORN.addItem(type, text);
    }

    Vue.popcorn = Vue.prototype.$popcorn = popcorn;
  },
};
