<template>
  <v-modal :show.sync="value" :on-close="close">
    <div v-frag v-if="room != null">
      <v-modal-header :title="room.name" :on-close="close" />

      <v-modal-body>
        <p>{{ $t('_word.select_rooms_and_guests') }}</p>

        <br>

        <v-dropdown
          :label="$t('_field.number_of_rooms')"
          :required="true"
          :clearable="false"
          v-model="number_of_rooms"
          :values-reduce="(item) => item.key"
          :values="rooms"
        />

        <v-dropdown
            :label="$t('_field.number_of_guests')"
            :tooltip="$t('_word.total_guests_of_all_rooms_combined')"
            :required="true"
            :clearable="false"
            v-model="number_of_guests"
            :values-reduce="(item) => item.key"
            :values="guests"
        />

        <br>

        <v-button :label="$t('_button.next')" align="full" :icon-before="false" icon="east" :click="add" />
      </v-modal-body>
    </div>
  </v-modal>
</template>

<script>
import VModal from "@/components/modal/Modal";
import VModalHeader from "@/components/modal/ModalHeader";
import VModalBody from "@/components/modal/ModalBody";
import VDropdown from "@/components/form/Dropdown";
import VInput from "@/components/form/Input";
import VButton from "@/components/form/Button";

function values() {
  return {
    number_of_rooms: 1,
    number_of_guests: 2,
  };
}

export default {
  components: {
    VButton,
    VInput,
    VDropdown,
    VModalBody,
    VModalHeader,
    VModal,
  },

  props: {
    value: { required: true },
    room: {
      type: Object,
    },
    reservation: {
      type: Object,
    },
    next: {
      type: Function
    }
  },

  data() {
    return values();
  },

  computed: {
    rooms() {
      let output = [];

      for (let i = 1; i <= this.room.max_availability; i++) {
        output.push({
          label: this.$tc("_word.x_rooms", i, { rooms: i }),
          key: i,
        });
      }

      return output;
    },
    guests() {
      let output = [];

      for (let i = this.number_of_rooms; i <= this.room.max_persons * this.number_of_rooms; i++) {
        output.push({
          label: this.$tc("_word.x_guests", i, { guests: i }),
          key: i,
        });
      }

      return output;
    }
  },

  watch: {
    number_of_rooms() {
      this.number_of_guests = this.number_of_rooms * this.room.max_persons;
    },
    value() {
      if(this.value) {
        let current = this.reservation.rooms.find((it) => {
          return it.id == this.room.id;
        });

        if (current != null) {
          this.number_of_guests = current.number_of_guests;
          this.number_of_rooms = current.number_of_rooms;
        }
        else {
          this.number_of_guests = this.room.max_persons;
        }
      }
    }
  },

  methods: {
    close() {
      this.$emit("input", false);
      setTimeout(() => {
        Object.assign(this.$data, values());
      }, 300);
    },
    add() {

      let current = this.reservation.rooms.find((it) => {
        return it.id == this.room.id;
      });

      if (current == null) {

        this.reservation.rooms.push({
          id: this.room.id,
          data: this.room,
          number_of_rooms: this.number_of_rooms,
          number_of_guests: this.number_of_guests,
          total_price: this.room.total_price,
        });
      }
      else {

        current.number_of_guests = this.number_of_guests;
        current.number_of_rooms = this.number_of_rooms;
      }

      this.close();
      this.next();
    },
  },
};
</script>
