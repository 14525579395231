var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row-wrapper"},[_c('div',{staticClass:"row",class:{ 'mobile-revert': _vm.mobileRevert },style:(_vm.spacing != null
        ? 'margin-left: -' +
          _vm.spacing +
          'px; width: calc(100% + ' +
          _vm.spacing * 2 +
          'px);'
        : '')},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }