<template>
  <div class="holder" style="background-image: url('/assets/bg.jpg')">
    <div class="overlay"></div>

    <div class="box">
      <h2 class="title">{{ $t("_word.select_stay") }}</h2>

      <date-picker
        :disabled-date="
          (date) => {
            return date.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
          }
        "
        :inline="true"
        v-model="selectedDate"
        range
      />

      <br />
      <br />
      <br />

      <v-button
        align="full"
        :click="confirm"
        :spinning="loading"
        :disabled="!allowNextStep"
        :icon="allowNextStep ? 'east' : null"
        :icon-before="false"
        :label="$t('_button.next')"
      />
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import VButton from "@/components/form/Button";

import "vue2-datepicker/locale/nl";

export default {
  components: {
    VButton,
    DatePicker,
  },

  props: {
    activeStep: {},
    next: {
      type: Function,
    },
    reservation: {
      type: Object,
    },
  },

  data() {
    return {
      selectedDate: null,
      loading: false,
    };
  },

  methods: {
    confirm() {
      this.reservation.start_date = this.selectedDate[0].toISOString();
      this.reservation.end_date = this.selectedDate[1].toISOString();

      this.next();

      this.loading = true;
    },
  },

  computed: {
    allowNextStep() {
      return (
        this.selectedDate != null &&
        this.selectedDate[0].toISOString() !==
          this.selectedDate[1].toISOString()
      );
    },
  },

  watch: {
    activeStep() {
      this.loading = false;
    },
  },

  created() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    if(params.start_date && params.end_date) {

      this.selectedDate = [
        new Date(params.start_date),
        new Date(params.end_date)
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
.holder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: auto;
  padding: 0 20px;
  box-sizing: border-box;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
  }
}

.title {
  text-align: center;
  margin-bottom: 30px;
}

.box {
  z-index: 2;
  background: var(--card-background-color);
  border-radius: var(--card-border-radius);
  box-shadow: var(--card-box-shadow);
  width: 400px;
  padding: 40px 30px 30px 30px;
}

@media only screen and (max-width: 700px) {
  .box {
    padding: 40px 25px 30px 25px;
  }
}
</style>
