<template>
  <div class="cost-table">
    <div class="cost-item" v-for="room in reservation.rooms">
      <div class="main-item">
        {{ room.number_of_rooms + "x " + room.data.name }}

        <div class="price">
          <div
            class="base"
            :class="{ original: roomPriceDiscount(room) !== roomPrice(room) }"
          >
            {{ formatPrice(roomPrice(room)) }}
          </div>
          <div
            v-if="roomPriceDiscount(room) !== roomPrice(room)"
            class="discounted"
          >
            {{ formatPrice(roomPriceDiscount(room)) }}
          </div>
        </div>
      </div>
      <div class="sub-item" v-if="roomPriceDiscount(room) > 0">
        {{
          $t("_word.tourist_tax") +
          " - " +
          $t("_word.x_per_person_per_night", {
            x: formatPrice(room.data.per_night_per_person_tax),
          })
        }}

        <div class="price">
          {{ formatPrice(touristTax(room)) }}
        </div>
      </div>
      <div class="sub-item" v-if="roomPriceDiscount(room) > 0">
        {{
          $t("_word.tax") +
          " (" +
          $t("_word.included_in_price").toLowerCase() +
          ")"
        }}

        <div class="price">
          {{ formatPrice(roomPriceDiscount(room) * 0.09) }}
        </div>
      </div>
    </div>
    <div class="cost-total">
      {{ $t("_word.total") }}

      <div class="total">
        {{ formatPrice(total) }}
      </div>
    </div>

    <div
      class="add-coupon"
      @click="
        () => {
          showCouponModal = true;
        }
      "
      v-if="this.reservation.coupon == null"
    >
      <div class="add-coupon-button">
        <span class="material-icons">add</span>
        {{ $t("_button.add_coupon") }}
      </div>
    </div>

    <div class="cost-discount" v-if="this.reservation.coupon != null">
      {{ discountText }}

      <div
        class="remove"
        @click="
          () => {
            this.reservation.coupon = null;
          }
        "
      >
        <span class="material-icons">close</span>
      </div>
    </div>

    <coupon-modal :reservation="reservation" v-model="showCouponModal" />

    <slot />
  </div>
</template>

<script>
import VButton from "@/components/form/Button";
import CouponModal from "../modal/Coupon";

export default {
  components: {
    VButton,
    CouponModal,
  },

  props: {
    reservation: {
      type: Object,
    },
    grandTotal: {
      type: Number
    }
  },

  data() {
    return {
      showCouponModal: false,
    };
  },

  methods: {
    roomPrice(item) {
      return item.number_of_rooms * item.total_price;
    },
    roomPriceDiscount(item) {

      if(this.reservation.coupon != null) {
        if (this.reservation.coupon.type === "percentage") {
          return (
              item.number_of_rooms * item.total_price * ((100-this.reservation.coupon.percentage_amount) / 100)
          );
        }
      }

      return item.number_of_rooms * item.total_price;
    },

    touristTax(item) {
      return (
        item.number_of_guests *
        item.data.nights *
        item.data.per_night_per_person_tax
      );
    },
  },

  computed: {
    discount() {
      let discount = 0;

      if (this.reservation.coupon != null) {
        if (this.reservation.coupon.type === "fixed") {

          discount = this.reservation.coupon.fixed_amount;
        } else if (this.reservation.coupon.type === "percentage") {

          let total = 0;
          this.reservation.rooms.forEach((room) => {
            total += this.roomPrice(room);
          });

          discount = total * (this.reservation.coupon.percentage_amount / 100);
        } else if (this.reservation.coupon.type === "nights") {
          let discountedNights = this.reservation.coupon.nights_amount;
          let totalNights = 0;

          this.reservation.rooms.forEach((room) => {
            totalNights += room.number_of_rooms * room.data.nights;
          });

          discount = this.subtotal / totalNights * discountedNights;
        }
      }

      return (discount > this.subtotal ? this.subtotal : discount);
    },
    discountText() {

      if (this.reservation.coupon.type === "fixed") {
        return this.$t('_word.fixed_discount', {amount: this.formatPrice(this.discount)});
      }
      if (this.reservation.coupon.type === "percentage") {

        return this.$t('_word.percentage_discount', {amount: this.formatPrice(this.discount), percentage: this.reservation.coupon.percentage_amount});
      }
      if (this.reservation.coupon.type === "nights") {

        return this.$t('_word.nights_discount', {amount: this.formatPrice(this.discount), nights: this.reservation.coupon.nights_amount});
      }

      return ''
    },
    subtotal() {
      let total = 0;

      this.reservation.rooms.forEach((room) => {
        total += this.roomPrice(room);
        total += this.touristTax(room);
      });

      return total;
    },
    total() {
      return this.subtotal - this.discount;
    },
  },

  watch: {
    total() {
      this.$emit('update:grandTotal', this.total);
    }
  },

  created() {
    this.$emit('update:grandTotal', this.total);
  }
};
</script>

<style lang="scss" scoped>
.cost-table {
  background: var(--card-background-color);
  border-radius: var(--card-border-radius);
  box-shadow: var(--card-box-shadow);
  box-sizing: border-box;
  width: 100%;

  .cost-item {
    padding: 30px;

    .main-item {
      display: flex;
      font-size: 20px;
      color: #222222;
      font-weight: 500;
      margin-bottom: 10px;

      .price {
        margin-left: auto;
        display: flex;

        .base {

        }
        .original {
          font-weight: 300;
          text-decoration: line-through;
          color: #888;
          font-size: 17px;
          display: flex;
          margin-top: 3px;
        }
        .discounted {
          margin-left: 10px;
        }
      }
    }
    .sub-item {
      display: flex;
      margin-bottom: 5px;
      color: #888;

      .price {
        margin-left: auto;
      }
    }
  }
  .cost-total {
    display: flex;
    padding: 20px 30px 10px 30px;
    font-size: 22px;
    color: #222222;
    font-weight: 500;

    border-width: 1px 0 0 0;
    border-color: var(--border-color);
    border-style: solid;

    .total {
      margin-left: auto;
    }
  }

  .add-coupon {
    display: flex;
    padding: 10px 30px 20px 30px;

    .add-coupon-button {
      display: flex;
      align-items: center;
      color: #888;
      cursor: pointer;
      padding: 8px 15px;
      border-radius: 30px;
      font-weight: 500;
      background: #fafafa;
      margin-left: auto;
      transition: background-color 0.3s;

      span {
        position: relative;
        margin-right: 10px;
        font-size: 20px;
      }

      &:hover {
        background: #f3f3f3;
      }
    }
  }

  .cost-discount {
    margin-top: -5px;
    padding: 0 30px 15px 30px;
    display: flex;
    align-items: center;
    color: #888;

    .remove {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--primary-color);
      cursor: pointer;
      padding: 7px;
      border-radius: 30px;
      transition: background-color 0.3s;
      margin-left: 10px;
      margin-top: -2px;

      span {
        position: relative;
        font-size: 23px;
        bottom: -1px;
      }

      &:hover {
        background: #fafafa;
      }
    }
  }
}
</style>
