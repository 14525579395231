import Prompt from "../components/modal/Prompt";

export default {
  install(Vue) {
    const PromptEL = Vue.extend(Prompt);
    let PROMPT = null;

    function prompt(title, description, buttons) {
      if (PROMPT == null) {
        PROMPT = new PromptEL();
        let vm = PROMPT.$mount();
        document.querySelector("body").appendChild(vm.$el);
      }
      Vue.nextTick(function () {
        PROMPT.open(title, description, buttons);
      });
    }
    function closePrompt() {
      if(PROMPT != null) PROMPT.close();
    }

    Vue.support = Vue.prototype.$prompt = prompt;
    Vue.support = Vue.prototype.$closePrompt = closePrompt;
  },
};
