import app from "../main";

export default {
  methods: {
    formatDate: function (date, format = "dd-MM-yyyy") {
      if (date == null) {
        return "-";
      }
      date = date.replaceAll(" ", "T");

      let d = new Date(date);

        const z = {
            M: d.getMonth() + 1,
            d: d.getDate(),
            h: d.getHours(),
            m: d.getMinutes(),
            s: d.getSeconds(),
        };

        format = format.replace(/(M+|d+|h+|m+|s+)/g, function (v) {
        return ((v.length > 1 ? "0" : "") + eval("z." + v.slice(-1))).slice(-2);
      });

      format = format.replace(/(f+)/g, function () {
        return app.$t("_word._date.month_" + (d.getMonth() + 1) + "_short");
      });

      format = format.replace(/(F+)/g, function () {
        return app.$t("_word._date.month_" + (d.getMonth() + 1));
      });

      format = format.replace(/(y+)/g, function (v) {
        return d.getFullYear().toString().slice(-v.length);
      });

      return format;
    },

    formatPrice: function (price) {
      return new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
      })
        .format(price)
        .replaceAll(",00", ",-");
    },
  },
};
