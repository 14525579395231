<template>
  <div class="spinner">
    <div class="spinner-inner" :style="holderStyle">
      <div :style="spinnerStyle + spinner1Style"></div>
      <div :style="spinnerStyle + animationStyle + spinner2Style"></div>
      <div :style="spinnerStyle + animationStyle + spinner3Style"></div>
      <div :style="spinnerStyle + spinner4Style"></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "VSpinner",

    props: {
      size: {
        type: Number,
        default: 30
      },
      color: {
        type: String,
        default: "var(--primary-color)"
      }
    },

    computed: {
      holderStyle() {
        return 'width: ' + this.size + 'px; height: ' + (this.size/2.307) + 'px;'
      },
      spinnerStyle() {
        return 'background-color: ' + this.color + '; top: ' + this.size / 6 + 'px; width: ' + this.size / 5 + 'px; height: ' + this.size / 5 + 'px;';
      },
      spinner1Style() {
        return 'left: ' + this.size / 15 + 'px;'
      },
      spinner2Style() {
        return 'left: ' + this.size / 15 + 'px;'
      },
      spinner3Style() {
        return 'left: ' + this.size / 2.5 + 'px;'
      },
      spinner4Style() {
        return 'left: ' + this.size / 1.41 + 'px;'
      },
      animationStyle() {
        return '--translate-final: ' + this.size / 3 + 'px;'
      }
    }
  }
</script>

<style lang="scss" scoped>

.spinner-inner {
  position: relative;
}
.spinner-inner div {
  position: absolute;
  border-radius: 50%;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.spinner-inner div:nth-child(1) {
  animation: spinner-inner1 0.4s infinite;
}
.spinner-inner div:nth-child(2) {
  animation: spinner-inner2 0.4s infinite;
}
.spinner-inner div:nth-child(3) {
  animation: spinner-inner2 0.4s infinite;
}
.spinner-inner div:nth-child(4) {
  animation: spinner-inner3 0.4s infinite;
}
@keyframes spinner-inner1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes spinner-inner3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes spinner-inner2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(var(--translate-final), 0);
  }
}

</style>
