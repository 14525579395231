import VueI18n from 'vue-i18n'
import merge from "lodash.merge";
import Cookies from "js-cookie";

export default function createI18n(activeLanguages) {
  let i18n = new VueI18n({
    locale: 'nl-NL',
    fallbackLocale: 'nl-NL',
    messages: {}
  });

  activeLanguages.forEach((language) => {
    if (Object.keys(i18n.getLocaleMessage(language)).length === 0) {
      const messages = merge(
        require(`../lang/${language}.json`),
        window.languages[language]
      );
      i18n.setLocaleMessage(language, messages);
    }
  });

  delete window.languages;

  if(Cookies.get("language") != null) {
    if(activeLanguages.includes(Cookies.get("language"))) {
      i18n.locale = Cookies.get("language");
    }
  }

  return i18n;
}
