var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popover",class:_vm.size},[_c('div',{staticClass:"popover-inner"},_vm._l((_vm.actions.filter((item) => {
        return (
          (item.show === undefined || item.show) &&
          (_vm.format === 'mobile'
            ? item.mobile === undefined || item.mobile
            : true) &&
          (_vm.format === 'tablet'
            ? item.tablet === undefined || item.tablet
            : true) &&
          (_vm.format === 'desktop'
            ? item.desktop === undefined || item.desktop
            : true)
        );
      })),function(action,index){return _c('div',{key:index},[(action.type === 'hr')?_c('div',{staticClass:"popover-hr"},[(action.label != null && action.label !== '')?_c('div',{staticClass:"hr-text"},[_vm._v(" "+_vm._s(action.label)+" ")]):_c('div',{staticClass:"only-hr"})]):_vm._e(),(action.type === 'action' || action.type == null)?_c('div',{staticClass:"popover-action",on:{"click":function($event){return _vm.doAction(action)}}},[(action.icon)?_c('div',{staticClass:"icon"},[_c('span',{staticClass:"material-icons-outlined"},[_vm._v(_vm._s(action.icon))])]):_vm._e(),(action.image)?_c('img',{attrs:{"src":action.image}}):_vm._e(),_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(action.label)+" ")])]):_vm._e()])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }