import { render, staticRenderFns } from "./ButtonHolder.vue?vue&type=template&id=6a0370af&scoped=true&"
import script from "./ButtonHolder.vue?vue&type=script&lang=js&"
export * from "./ButtonHolder.vue?vue&type=script&lang=js&"
import style0 from "./ButtonHolder.vue?vue&type=style&index=0&id=6a0370af&prod&lang=scss&scoped=true&"
import style1 from "./ButtonHolder.vue?vue&type=style&index=1&id=6a0370af&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a0370af",
  null
  
)

export default component.exports