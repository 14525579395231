var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"holder"},[(_vm.rooms == null)?_c('div',{staticClass:"empty"},[_c('v-spinner',{attrs:{"size":100}}),_c('h2',{staticClass:"empty-title"},[_vm._v(_vm._s(_vm.$t("_word.loading_availability")))])],1):_c('div',{staticClass:"inner"},[(_vm.availableRooms.length > 0)?_c('div',[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("_word.choose_room")))]),_c('div',{staticClass:"date",on:{"click":() => {
            _vm.goStep(1);
          }}},[_c('span',{staticClass:"material-icons"},[_vm._v("calendar_month")]),_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.reservation.start_date) + " - " + _vm.formatDate(_vm.reservation.end_date))+" ")])]),_c('div',{staticClass:"rooms"},_vm._l((_vm.availableRooms),function(room){return _c('div',{key:room.id,staticClass:"room"},[_c('img',{attrs:{"src":room.images.length == 0
                ? 'assets/default.jpg'
                : room.images[0].url}}),_c('div',{staticClass:"info"},[_c('h3',[_vm._v(_vm._s(room.name))]),_c('div',{staticClass:"persons"},[_c('span',{staticClass:"material-icons"},[_vm._v("person_outline")]),_vm._v(" "+_vm._s(_vm.$tc("_word.x_persons", room.max_persons, { x: room.max_persons, }))+" ")]),_c('div',{staticClass:"description"},[_c('p',{domProps:{"innerHTML":_vm._s(room.description[_vm.$i18n.locale])}})]),_c('div',{staticClass:"price"},[_c('div',{staticClass:"price-from"},[_vm._v(" "+_vm._s(_vm.$t("_word.starting_from"))+" ")]),_c('div',{staticClass:"price-amount"},[_c('div',{staticClass:"price-unit"},[_vm._v(" "+_vm._s(_vm.formatPrice(room.price_per_night))+" ")]),_c('div',{staticClass:"price-text"},[_vm._v(_vm._s(_vm.$t("_word.per_night")))])])]),_c('v-button',{attrs:{"click":() => {
                  _vm.showRoomModal = true;
                  _vm.selectedRoom = room;
                },"align":"full","icon-before":false,"label":_vm.reservation.rooms.find((it) => {
                  return it.id == room.id;
                }) == null
                  ? _vm.$t('_button.select')
                  : _vm.$t('_button.selected'),"icon":_vm.reservation.rooms.find((it) => {
                  return it.id == room.id;
                }) == null
                  ? 'east'
                  : 'check'}})],1)])}),0)]):_c('div',{staticClass:"no-availability"},[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("_word.no_rooms_available")))]),_c('p',[_vm._v(_vm._s(_vm.$t("_word.change_the_date_to_view_other_options")))]),_c('v-button',{attrs:{"click":() => {
            _vm.goStep(1);
          },"label":_vm.$t('_button.back_to_dates')}})],1),(_vm.unavailableRooms.length > 0)?_c('div',{staticClass:"unavailable-rooms"},[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("_word.rooms_without_availability")))]),_c('div',{staticClass:"rooms"},_vm._l((_vm.unavailableRooms),function(room){return _c('div',{key:room.id,staticClass:"room unavailable"},[_c('img',{attrs:{"src":room.images.length == 0
                ? 'assets/default.jpg'
                : room.images[0].url}}),_c('div',{staticClass:"info"},[_c('h3',[_vm._v(_vm._s(room.name))]),_c('div',{staticClass:"persons"},[_c('span',{staticClass:"material-icons"},[_vm._v("person_outline")]),_vm._v(" "+_vm._s(_vm.$tc("_word.x_persons", room.max_persons, { x: room.max_persons, }))+" ")]),_c('div',{staticClass:"description"},[_c('p',{domProps:{"innerHTML":_vm._s(room.description[_vm.$i18n.locale])}})])])])}),0)]):_vm._e()]),_c('room-modal',{attrs:{"reservation":_vm.reservation,"room":_vm.selectedRoom,"next":_vm.next},model:{value:(_vm.showRoomModal),callback:function ($$v) {_vm.showRoomModal=$$v},expression:"showRoomModal"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }