<template>
  <v-modal :show="show" :on-close="close">
    <v-modal-header
      :title="title"
      :on-close="close" />

    <v-modal-body>
      <p v-html="description" />

      <v-button-holder align="right">
        <v-button
          v-for="button in buttons"
          :key="button.label"
          :click="() => {
            Vue.set(button, 'spinning', true)
            button.click();
          }"
          :spinning="button.spinning"
          :label="button.label" />
      </v-button-holder>
    </v-modal-body>
  </v-modal>
</template>

<script>

import Vue from "vue";
import VModal from "@/components/modal/Modal";
import VModalHeader from "@/components/modal/ModalHeader";
import VModalBody from "@/components/modal/ModalBody";
import VButtonHolder from "@/components/form/ButtonHolder";
import VButton from "@/components/form/Button";

export default {
  components: {
    VButton,
    VButtonHolder,
    VModalBody,
    VModalHeader,
    VModal
  },

  data() {
    return {
      show: false,
      title: '',
      description: '',
      buttons: {},
      Vue: Vue
    }
  },

  methods: {
    close () {
      this.show = false;
    },
    open (title, description, buttons) {
      this.show = true
      this.title = title;
      this.description = description;
      this.buttons = buttons;
    }
  }
}

</script>
