<template>
  <div class="input-holder" :class="{ disabled: disabled }">

    <v-field-label
      :required="required"
      :tooltip="tooltip"
      :label="label"/>

    <div
      class="input-item"
      :class="{ error: error.$error, disabled: disabled }"
    >
      <div class="prefix" v-if="prefix != null">
        {{ prefix }}
      </div>

      <input
        :type="type"
        :value="value"
        :min="min"
        :max="max"
        :step="step"
        :class="{ 'has-prefix': prefix != null, 'has-suffix': suffix != null }"
        @input="keyup"
        :placeholder="placeholder"
        :disabled="disabled"
      />

      <div class="suffix" v-if="suffix != null">
        {{ suffix }}
      </div>
    </div>

    <div
      class="input-wrong"
      v-for="(message, errorItem) in errors"
      v-if="firstError == errorItem"
    >
      {{ message() }}
    </div>
  </div>
</template>

<script>
import VFieldLabel from "@/components/form/FieldLabel";
export default {
  name: "VInput",
  components: {VFieldLabel},
  data() {
    return {
      errors: {
        required: () => {
          return this.$t("_error.field_required_short");
        },
        email: () => {
          return this.$t("_error.wrong_email_syntax_short");
        },
        minLength: () => {
          return this.$t("_error.min_length_x_short", {
            min:
              this.error.$params == null
                ? null
                : this.error.$params.minLength.min,
          });
        },
        maxLength: () => {
          return this.$t("_error.max_length_x_short", {
            max:
              this.error.$params == null
                ? null
                : this.error.$params.maxLength.max,
          });
        },
        minValue: () => {
          return this.$t("_error.min_value_x_short", {
            min:
              this.error.$params == null
                ? null
                : this.error.$params.minValue.min,
          });
        },
        maxValue: () => {
          return this.$t("_error.max_value_x_short", {
            max:
              this.error.$params == null
                ? null
                : this.error.$params.maxValue.max,
          });
        },
        url: () => {
          return this.$t("_error.url_not_valid_short");
        },
        passwordComplexity: () => {
          return this.$t("_error.password_complexity_short");
        },
        sameAsPassword: () => {
          return this.$t("_error.field_does_not_match_short");
        }
      },
    };
  },

  computed: {
    firstError() {
      for(const errorItem in this.errors) {
        if(this.error.$error && this.error[errorItem] != null && !this.error[errorItem]) {
          return errorItem;
        }
      }
      return null;
    }
  },

  props: {
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    tooltip: {
      type: String,
      default: null,
    },
    value: {
      required: true,
    },
    type: {
      type: String,
      default: "text",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Object,
      default: () => {
        return {};
      },
    },
    suffix: {
      type: String,
      default: null,
    },
    prefix: {
      type: String,
      default: null,
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    step: {
      type: Number,
      default: null,
    },
  },

  methods: {
    keyup(e) {
      if (this.type === "slug") {
        let cvalue = e.target.value
          .toLowerCase() // LowerCase
          .replace(/\s+/g, "-") // space to -
          .replace(/&/g, `-and-`) // & to and
          .replace(/--/g, `-`); // -- to -

        e.target.value = cvalue;
      }

      if (this.type === "text") var start = e.target.selectionStart;
      this.$emit("input", e.target.value);

      if (this.type === "text") {
        this.$nextTick(() => {
          this.$nextTick(() => {
            e.target.selectionStart = e.target.selectionEnd = start;
          });
        });
      }
    }
  },

};
</script>

<style lang="scss" scoped>
.input-holder {
  position: relative;
  margin-bottom: 15px;
  width: 100%;

  .input-item {
    border-radius: var(--form-field-border-radius);
    border-style: solid;
    border-width: var(--form-field-border-width);
    border-color: var(--form-field-border-color);
    background: var(--form-field-background-color);
    box-shadow: var(--form-field-box-shadow);
    margin-top: 7px;
    transition: all 0.2s;
    display: flex;
    overflow: hidden;

    &.disabled {
      background: var(--form-field-disabled-background-color);
      color: var(--form-field-disabled-color);
    }

    input {
      padding: var(--form-field-input-padding);
      color: var(--form-field-color);
      border: none;
      font-size: var(--form-field-font-size);
      font-weight: var(--form-field-font-weight);
      background: transparent;
      width: 100%;
      flex: 1;

      &::placeholder {
        color: var(--form-field-placeholder-color);
        font-weight: 400;
      }

      &[disabled] {
        background: var(--form-field-disabled-background-color);
        color: var(--form-field-disabled-color);
      }

      &.has-prefix {
        padding-left: 18px;
      }
      &.has-suffix {
        padding-right: 10px;
      }
    }

    .prefix,
    .suffix {
      color: var(--form-field-prefix-color);
      font-size: var(--form-field-font-size);
      font-weight: var(--form-field-font-weight);
      padding: var(--form-field-input-padding);
      border-color: var(--form-field-border-color);
      border-style: solid;
      padding-top: 7px;
      padding-bottom: 5px;
      margin: 7px 0;
    }
    .prefix {
      border-width: 0 2px 0 0;
    }
    .suffix {
      border-width: 0 0 0 2px;
    }
  }

  .input-item.error {
    border-color: var(--form-field-error-border-color);
    background: var(--form-field-error-background-color);
  }

  .input-wrong {
    position: absolute;
    right: 0;
    bottom: -18px;
    color: var(--form-field-error-color);
    letter-spacing: var(--form-field-error-letter-spacing);
    font-size: var(--form-field-error-font-size);
    font-weight: var(--form-field-error-font-weight);
    text-align: right;
  }
}
</style>
