<template>
  <div class="v-button-holder" :class="align">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "VButtonHolder",
  props: {
    align: {
      type: String,
      default: "left",
      validator(value) {
        return ["left", "center", "right"].includes(value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.v-button-holder {
  display: flex;
  padding-top: 11px;

  &.center {
    display: flex;
    justify-content: center;
  }
  &.right {
    display: flex;
    justify-content: flex-end;
  }
}
</style>

<style lang="scss">
.v-button-holder {

  & > * {
    margin: 0 14px 0 0;
  }

  &.center {
    display: flex;
    justify-content: center;

    & > * {
      margin: 0 7px;
    }
  }
  &.right {
    display: flex;
    justify-content: flex-end;

    & > * {
      margin: 0 0 0 14px;
    }
  }
}
</style>
