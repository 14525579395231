<template>
  <div class="popcorn-holder" :class="{ 'in-modal': inModal }">
    <div
      class="popcorn"
      :class="item.type"
      v-for="(item, id) in items"
      :key="id"
      @click="removeItem(id)"
    >
      <div class="popcorn-icon" :class="item.type">
        <i class="material-icons">{{
          item.type === "success" ? "check" : "clear"
        }}</i>
      </div>
      <div class="popcorn-text">{{ item.text }}</div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

let ItemID = 0;

export default {
  data() {
    return {
      items: {},
    };
  },

  computed: {
    inModal() {
      return this.$root.openModals != null && this.$root.openModals.length > 0;
    },
  },

  methods: {
    addItem(type, text) {
      ItemID++;
      Vue.set(this.items, ItemID, { type: type, text: text });
      setTimeout(this.removeItem.bind(null, ItemID), 6000);
    },
    removeItem(id) {
      Vue.delete(this.items, id);
    },
  },
};
</script>

<style scoped lang="scss">
.popcorn-holder {
  position: fixed;
  bottom: 35px;
  width: 600px;
  margin-left: calc(50% - 300px);
  z-index: 9999999;

  .popcorn {
    background: var(--popcorn-background-color);
    border-radius: 10px;
    width: 100%;
    box-shadow: var(--popcorn-shadow);
    font-weight: 300;
    font-size: 13px;
    overflow: hidden;

    border-color: var(--popcorn-border-color);
    border-width: 1px 1px 1px 3px;
    border-style: solid;

    margin-top: 10px;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    animation-name: fadeInOpacity;
    animation-timing-function: ease-in;
    animation-duration: 0.3s;

    &.success {
      border-left-color: var(--popcorn-success-color);
    }
    &.error {
      border-left-color: var(--popcorn-error-color);
    }

    .popcorn-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 17px;
      width: 17px;
      margin-left: 23px;
      border-radius: 50%;
      color: #fff;
      position: relative;
      top: 0px;

      &.success {
        background: var(--popcorn-success-color);
      }
      &.error {
        background: var(--popcorn-error-color);
      }

      i {
        font-size: 11px;
        position: relative;
        font-weight: 900;
      }
    }

    .popcorn-text {
      padding: 16px 20px;
      font-size: 15px;
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 132px);
      color: var(--popcorn-text-color);
    }
  }

  @media only screen and (max-width: 767px) {
    & {
      bottom: 90px;

      &.in-modal {
        bottom: 20px;
      }
    }
  }

  @media only screen and (max-width: 640px) {
    & {
      width: calc(100% - 20px);
      margin-left: 10px;

      .popcorn {
        .popcorn-text {
          padding: 13px 15px;
        }
      }
    }
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
