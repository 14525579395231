<template>
  <div>
    <div class="modal-header">
      <div class="left">
        <h3 v-if="title">{{ title }}</h3>
        <p v-if="backText != null" @click="onBack">
          <span class="material-icons">keyboard_arrow_left</span> {{ backText }}
        </p>
      </div>
      <div @click="close" class="modal-close">
        <span class="material-icons">close</span>
      </div>
    </div>
    <div class="modal-header-bottom" v-if="tabs != null || search != null">
      <div class="modal-tabs" v-if="tabs != null">
        <div
          class="modal-tab"
          :class="tab.active ? 'active' : ''"
          v-for="tab in tabs"
          @click="tab.click"
        >
          {{ tab.label }}
        </div>
      </div>
      <div class="modal-action-box" :class="{ 'no-tabs': tabs == null }">
        <div
          class="modal-search"
          v-if="search != null && (search.show == null ? true : search.show)"
        >
          <span class="material-icons">search</span>
          <input
            :placeholder="$t('_word.start_searching') + '...'"
            v-on:keyup="runSearch"
            v-model="filters.search"
          />
        </div>
        <div
          v-for="action in actions"
          v-show="action.show == null ? true : action.show"
        >
          <v-popover
            :offset="[0, 5]"
            placement="bottom-end"
            :actions="action.actions"
            v-if="action.popover"
          >
            <div
              class="action-button"
              :class="{ 'with-text': action.label != null }"
            >
              <span class="action-button-icon material-icons">{{
                action.icon == null ? "more_horiz" : action.icon
              }}</span>
              <span class="action-button-label" v-if="action.label != null">{{
                action.label
              }}</span>
            </div>
          </v-popover>
          <div
            class="action-button with-text colored"
            v-if="!action.popover"
            @click="action.click"
            v-show="action.show == null ? true : action.show"
          >
            <span class="action-button-icon material-icons">{{
              action.icon
            }}</span>
            <span class="action-button-label">{{ action.label }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VModalHeader",

  data() {
    return {
      filters: {},
    };
  },

  props: {
    title: {
      type: String,
    },
    backText: {
      type: String,
      default: null,
    },
    onBack: {
      type: Function,
      default: () => {
        return () => {};
      },
    },
    onClose: {
      required: true,
    },
    tabs: {
      required: false,
    },
    search: {
      required: false,
      default: null
    },
    actions: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  computed: {
    filtersData() {
      if (this.search == null) return null;
      let filters = this.$store.getters[this.search.vuex + "/filters"];
      return filters == null ? {} : filters;
    },
  },
  created() {
    if (this.filtersData != null) this.filters = { ...this.filtersData };
  },

  methods: {
    close() {
      this.onClose();
    },
    runSearch() {
      if (this.filters.search === "") delete this.filters.search;

      let custom = { ...this.search.custom };
      if (custom == null) custom = {};

      this.$store.dispatch(this.search.vuex + "/fetchList", {
        filters: this.filters,
        pagination: { current_page: 1 },
        ...custom,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-header {
  padding: 38px 50px 0 50px;
  margin-bottom: -10px;
  display: flex;
  align-items: center;

  .left {
    padding-bottom: 10px;

    h3 {
      margin: 0;
      font-size: 23px;
      font-weight: 800;
      padding-right: 10px;
    }
    p {
      margin: 0;
      margin-top: -3px;
      color: var(--modal-back-text-color);
      font-weight: 400;
      cursor: pointer;

      span {
        position: relative;
        top: 7px;
        right: -5px;
        margin-left: -10px;
        color: var(--modal-back-icon-color);
      }
    }
  }

  .modal-close {
    position: relative;
    top: -15px;
    right: -4px;
    margin-left: auto;
    cursor: pointer;
    height: 39px;
    width: 39px;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    display: flex;
    flex: none;

    background: var(--modal-close-button-background);
    border-style: solid;
    border-width: var(--modal-close-button-border-width);
    border-color: var(--modal-close-button-border-color);
    color: var(--modal-close-button-color);
    box-shadow: var(--modal-close-button-box-shadow);

    span {
      font-size: 24px;
      position: relative;
    }
  }

  @media only screen and (max-width: 500px) {
    & {
      padding: 30px 25px 0 25px;

      .modal-close {
        top: -9px;
      }
    }
  }
}
.modal-header-bottom {
  border-style: solid;
  border-width: 0 0 2px 0;
  border-color: var(--modal-border-color);
  margin: 5px 50px 0 50px;
  width: auto;
  display: flex;

  .modal-tabs {
    display: flex;
    width: 100%;
    flex: 1;

    .modal-tab {
      margin-right: 24px;
      padding: 9px 0;
      cursor: pointer;
      font-size: 16px;
      color: var(--modal-tab-color);
      margin-bottom: -2px;

      &.active {
        border-width: 0 0 3px 0;
        border-color: var(--modal-active-tab-border-color);
        border-style: solid;
        color: var(--modal-active-tab-color);
      }
    }
  }
  .modal-action-box {
    display: flex;
    align-items: center;
    height: 38px;

    &.no-tabs {
      margin-left: auto;
    }

    .modal-search {
      display: flex;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ccc;
        font-size: 21px;
      }

      input {
        width: 100%;
        background: none;
        border: none;
        padding-left: 7px;
        font-size: 16px;
        color: #222;
        font-weight: 400;

        &::placeholder {
          color: #bbb;
          font-size: 16px;
        }
      }
    }

    .action-button {
      border-radius: 15px;
      min-width: 38px;
      font-size: 15px;
      color: #222;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: 10px;

      &.with-text {
        color: #bbb;

        & .action-button-icon {
          margin-right: 5px;
        }

        @media only screen and (max-width: 700px) {
          & {
            padding: 0;

            & .action-button-icon {
              margin-right: 0;
            }
            & .action-button-label {
              display: none;
            }
          }
        }
      }

      & .action-button-icon {
        color: #bbb;
      }
    }
  }
}
</style>
