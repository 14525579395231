<template>
  <div class="textarea-holder" :class="{ disabled: disabled }">
    <v-field-label
      :required="required"
      :tooltip="tooltip"
      :label="label"/>

    <textarea
      :class="{error: error.$error, disabled: disabled}"
      :value="value"
      :placeholder="placeholder"
      :disabled="disabled"
      :style="'height: '+height + 'px'"
      @input="keyup" />

    <div
      class="textarea-error"
      v-for="(message, errorItem) in errors"
      v-if="error.$error && error[errorItem] != null && !error[errorItem]"
    >
      {{ message() }}
    </div>
  </div>
</template>

<script>
import VFieldLabel from "@/components/form/FieldLabel";
export default {
  name: 'VTextarea',
  components: {VFieldLabel},
  data() {
    return {
      errors: {
        required: () => {
          return this.$t("_error.field_required_short");
        },
        minLength: () => {
          return this.$t("_error.min_length_x_short", {
            min:
              this.error.$params == null
                ? null
                : this.error.$params.minLength.min,
          });
        },
        maxLength: () => {
          return this.$t("_error.max_length_x_short", {
            max:
              this.error.$params == null
                ? null
                : this.error.$params.maxLength.max,
          });
        }
      },
    };
  },

  props: {
    label: {
      type: String,
      default: null,
    },
    tooltip: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    value: {
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Object,
      default: () => {
        return {};
      },
    },
    height: {
      type: Number,
      default: 150
    }
  },
  methods: {
    keyup(e) {
      var start = e.target.selectionStart;
      this.$emit('input', e.target.value)
      this.$nextTick(()=>{
        this.$nextTick(()=>{
          e.target.selectionStart = e.target.selectionEnd = start;
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.textarea-holder {

  position: relative;
  margin-bottom: 15px;
  width: 100%;

  textarea {
    border-radius: var(--form-field-border-radius);
    border-style: solid;
    border-width: var(--form-field-border-width);
    border-color: var(--form-field-border-color);
    background: var(--form-field-background-color);
    box-shadow: var(--form-field-box-shadow);
    padding: var(--form-field-input-padding);
    color: var(--form-field-color);
    font-size: var(--form-field-font-size);
    font-weight: var(--form-field-font-weight);
    margin-top: 7px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    transition: all 0.2s;
    -webkit-appearance: none;

    resize: none;

    &.error {
      border-color: var(--form-field-error-border-color);
      background: var(--form-field-error-background-color);
    }

    &::placeholder {
      color: var(--form-field-placeholder-color);
      font-weight: 400;
    }

    &.disabled {
      background: var(--form-field-disabled-background-color);
      color: var(--form-field-disabled-color);
    }
  }

  .textarea-error {
    position: absolute;
    right: 0;
    bottom: -18px;
    color: var(--form-field-error-color);
    letter-spacing: var(--form-field-error-letter-spacing);
    font-size: var(--form-field-error-font-size);
    font-weight: var(--form-field-error-font-weight);
    text-align: right;
  }
}

</style>
