<template>
  <div class="popover" :class="size">
    <div class="popover-inner">
      <div
        v-for="(action, index) in actions.filter((item) => {
          return (
            (item.show === undefined || item.show) &&
            (format === 'mobile'
              ? item.mobile === undefined || item.mobile
              : true) &&
            (format === 'tablet'
              ? item.tablet === undefined || item.tablet
              : true) &&
            (format === 'desktop'
              ? item.desktop === undefined || item.desktop
              : true)
          );
        })"
        :key="index"
      >
        <div class="popover-hr" v-if="action.type === 'hr'">
          <div
            class="hr-text"
            v-if="action.label != null && action.label !== ''"
          >
            {{ action.label }}
          </div>
          <div class="only-hr" v-else></div>
        </div>

        <div
          class="popover-action"
          v-if="action.type === 'action' || action.type == null"
          @click="doAction(action)"
        >
          <div class="icon" v-if="action.icon">
            <span class="material-icons-outlined">{{ action.icon }}</span>
          </div>
          <img :src="action.image" v-if="action.image" />
          <div class="label">
            {{ action.label }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VPopoverInner",

  props: {
    actions: {
      type: Array,
      required: true,
    },
    size: {
      type: String,
      default: "normal",
      validator(value) {
        return ["normal", "small"].includes(value);
      },
    },
    show: {
      required: true,
      type: Boolean,
    },
  },

  methods: {
    doAction(item) {
      if (item.click != null) {
        item.click();
      }
      if (item.route != null) {
        this.$router.push(item.route).catch((err) => {});
      }
      this.$emit("update:show", false);
    },
    formatChange() {
      this.windowWidth = window.innerWidth;
    }
  },

  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },

  mounted() {
    window.addEventListener('resize', this.formatChange);
  },
  destroyed() {
    window.removeEventListener('resize', this.formatChange);
  },

  computed: {
    format() {
      return this.windowWidth > 1024
        ? "desktop"
        : this.windowWidth > 768
          ? "tablet"
          : "mobile";
    },
  },
};
</script>

<style lang="scss" scoped>
.popover {
  background: var(--popover-background-color);
  border-radius: 15px;
  box-shadow: var(--popover-box-shadow);
  border-style: solid;
  border-color: var(--popover-border-color);
  border-width: var(--popover-border-width);
  padding: 11px 0 5px 0;
  width: 250px;
  cursor: default;

  z-index: 10;

  .popover-inner {
    width: 100%;
  }

  .popover-action {
    padding: 9px;
    margin: 0 11px 6px 11px;
    border-radius: 12px;
    cursor: pointer;
    color: var(--popover-action-text-color);
    display: flex;
    justify-content: left;
    align-items: center;
    font-weight: 400;
    text-align: left;
    min-height: 33px;
    transition: background-color 0.2s;

    .icon {
      width: 33px;
      height: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 30px;
      margin-right: 12px;
      flex: none;
      transition: background-color 0.2s;

      background: var(--popover-icon-background-color);

      span {
        font-size: 19px;
        transition: color 0.2s;
        color: var(--popover-icon-color);
      }
    }
    .label {
      display: flex;
    }

    img {
      width: 22px;
      margin-right: 12px;
      margin-left: 5px;
    }

    &.center {
      justify-content: center;
      text-align: center;
    }

    &:hover {
      color: var(--popover-action-hover-text-color);
      background: var(--popover-action-hover-background-color);

      .icon {
        background: var(--popover-action-hover-icon-background-color);

        span {
          color: var(--popover-action-hover-icon-color);
        }
      }
    }
  }

  .popover-hr {
    border-width: 1px 0 0 0;
    border-style: solid;
    border-color: var(--popover-hr-color);
    margin-top: 20px;
    margin-bottom: 6px;
    padding-left: 18px;

    .hr-text {
      height: 17px;
      color: var(--popover-hr-text-color);
      font-size: 14px;
      margin-top: -9px;
      padding: 0 5px;
      background: var(--popover-background-color);
      font-weight: 300;
      width: fit-content;
    }
    .only-hr {
      margin-top: -4px;
      height: 9px;
    }
  }

  &.small {
    padding: 7px 1px 2px 1px;
    width: 230px;

    .popover-inner {
      .popover-action {
        padding: 6px 7px;
        margin: 0 6px 6px 6px;

        .icon {
          width: 30px;
          height: 30px;

          span {
            font-size: 17px;
          }
        }
      }

      .popover-hr {
        margin-top: 10px;
        margin-bottom: 4px;
      }
    }
  }
}
</style>
