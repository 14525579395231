import Vue from 'vue'
import App from './App.vue'
import VueI18n from 'vue-i18n'
import createI18n from "./plugins/i18n";
import loader from "./utilities/loader";
import Popcorn from "./plugins/popcorn";
import Mixins from "./mixins/index";
import Prompt from "./plugins/prompt";
import PortalVue from "portal-vue";
import VueObserveVisibility from "vue-observe-visibility";
import frag from 'vue-frag';
import Vuelidate from 'vuelidate'

import "vue-select/src/scss/vue-select.scss";
import vSelect from "vue-select";

import 'vue2-datepicker/index.css';

Vue.config.api_url = process.env.VUE_APP_API_URL;
Vue.config.productionTip = false
Vue.use(VueI18n);
Vue.use(Popcorn);
Vue.use(Prompt);
Vue.use(PortalVue);
Vue.use(VueObserveVisibility);
Vue.directive("frag", frag);
Vue.component("v-select", vSelect);
Vue.use(Vuelidate);

/*
 * i18n
 */
loader("languages", require.context("./lang", true, /\.json/), "json");
let languages = ['nl-NL', 'en-GB'];
Vue.prototype.$languages = languages;
const i18n = createI18n(languages);

/*
   * Register global mixins
   */
Mixins.forEach((Mixin) => {
  Vue.mixin(Mixin.default);
});

const app = new Vue({
  i18n,
  render: h => h(App),
}).$mount('#app');

window.app = app;

/*
 * Export app
 */
export default app;
