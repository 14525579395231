<template>
  <div class="navigation">
    <div class="nav-inner">
      <div class="steps">
        <div
          class="step"
          v-for="(step, key) in steps"
          :key="key"
          :class="{ active: key == activeStep, done: key <= lastStep }"
          @click="goStep(key)"
        >
          <div class="inner">
            <div class="icon">
              <span class="material-icons-outlined">{{
                key < activeStep ? "check" : step.icon
              }}</span>
            </div>
            <div class="text">
              {{ step.label }}
            </div>
          </div>
        </div>
      </div>

      <div class="nav-actions">
        <v-popover
          :offset="[0, 5]"
          placement="bottom-end"
          class="language-popover"
          :actions="languages"
        >
          <div class="language">
            <img :src="'/assets/languages/' + $i18n.locale + '.svg'" />

            {{ $t("_lang." + $i18n.locale) }}
          </div>
        </v-popover>

        <div class="close" @click="close">
          <span class="material-icons">close</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VPopover from "@/components/popover/Popover";
import setLanguage from "../../utilities/language"

export default {
  components: { VPopover },
  computed: {
    steps() {
      return {
        1: {
          icon: "date_range",
          label: this.$t("_word.date"),
        },
        2: {
          icon: "bed",
          label: this.$t("_word.rooms"),
        },
        3: {
          icon: "list",
          label: this.$t("_word.summary"),
        },
        4: {
          icon: "person_outline",
          label: this.$t("_word.details"),
        },
      };
    },
    languages() {
      let output = [];

      this.$languages.forEach((lan) => {
        output.push({
          image: "/assets/languages/" + lan + ".svg",
          label: this.$t("_lang." + lan),
          click: () => {
            setLanguage.setLanguage(lan);
          }
        });
      });

      return output;
    },
  },

  props: {
    activeStep: {},
    lastStep: {},
    goStep: {
      type: Function,
    },
  },

  methods: {
    close() {
      window.parent.postMessage(
        {
          func: "dshClose",
        },
        "*"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation {
  display: flex;
  height: 75px;
  box-sizing: border-box;
  width: 100%;
  border-width: 0 0 2px 0;
  border-style: solid;
  border-color: #f5f5f5;
  background: #fff;

  .nav-inner {
    width: 1540px;
    margin: 0 auto;
    padding: 18px 40px;
    display: flex;
    flex-direction: row;

    .steps {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .step {
        display: flex;
        flex-direction: row;
        cursor: default;
        justify-content: center;
        position: relative;

        .inner {
          display: flex;
          background: #fff;
          padding: 0 20px;
          z-index: 9;

          .icon {
            width: 36px;
            height: 36px;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 40px;

            span {
              color: #ccc;
              font-size: 19px;
            }
          }

          .text {
            display: flex;
            align-items: center;
            padding-left: 10px;
            color: #ccc;
          }
        }

        &::after {
          width: 400px;
          left: 50%;
          top: 18px;
          height: 2px;
          background: #eee;
          content: "";
          position: absolute;
          pointer-events: none;
        }

        &:nth-last-child(1) {
          &::after {
            background: #fff;
          }
        }

        &.done {
          cursor: pointer;

          .inner {
            .icon {
              span {
                color: var(--primary-color);
                font-size: 26px;
              }
            }

            .text {
              font-weight: 500;
              color: #222;
            }
          }
        }

        &.active {
          cursor: pointer;

          .inner {
            .icon {
              background: var(--navigation-icon-active-background-color);

              span {
                color: var(--navigation-icon-active-color);
                font-size: 19px;
              }
            }

            .text {
              font-weight: 500;
              color: #222;
            }
          }
        }
      }
    }

    .nav-actions {
      display: flex;
      align-items: center;
      z-index: 9;

      margin-left: 50px;
      padding-left: 70px;
      border-width: 0 0 0 2px;
      border-style: solid;
      border-color: var(--border-color);

      .language-popover {
        .language {
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;

          img {
            width: 24px;
            height: 24px;
            object-fit: cover;
            margin-right: 10px;
          }
        }
      }

      .close {
        margin-left: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        cursor: pointer;
        border-radius: 10px;
        transition: all 0.3s;
        background: #fff;
        border: 2px solid #f5f5f5;
        color: #222;

        span {
          font-size: 21px;
        }

        &:hover {
          background: var(--primary-color);
          border-color: var(--primary-color);
          color: #fff;
        }
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .nav-inner {
      .steps {
        .step {
          &::after {
            width: 300px;
          }

          .inner {
            .text {
              display: none;
            }
          }

          &.active {
            .inner {
              .text {
                display: flex;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 900px) {
    .nav-inner {
      .steps {
        .step {
          &::after {
            width: 150px;
          }

          .inner {
            padding: 0 10px;
          }

          &.active {
            .inner {
              padding: 0 20px;
            }
          }
        }
      }

      .nav-actions {
        margin-left: 30px;
        padding-left: 50px;

        .language-popover {
          .language {
            font-size: 0;

            img {
              width: 27px;
              height: 27px;
              object-fit: cover;
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 700px) {
    .nav-inner {
      padding: 18px 20px;

      .steps {
        .step {
          &::after {
            width: 150px;
          }

          .inner {
            padding: 0 4px;
          }

          &.active {
            .inner {
              .text {
                display: none;
              }
            }
          }
        }
      }

      .nav-actions {
        margin-left: 30px;
        padding-left: 0;
        border: none;

        .language-popover {
          .language {
            font-size: 0;

            img {
              width: 27px;
              height: 27px;
              object-fit: cover;
              margin-right: 0;
            }
          }
        }

        .close {
          margin-left: 25px;

          span {
            font-size: 21px;
          }

          &:hover {
            background: var(--primary-color);
            border-color: var(--primary-color);
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
