<template>
  <div class="input-label" :class="{ tooltip: tooltip != null }">
    {{ label }}<span class="required" v-if="required">*</span>

    <span class="material-icons" v-if="tooltip != null">help_outline</span>

    <div class="tooltip-text" v-if="tooltip != null" v-html="tooltip.replace('\n', '<br>')" />
  </div>
</template>

<script>
export default {
  name: "VFieldLabel",

  props: {
    label: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.input-label {
  font-size: var(--form-label-font-size);
  font-weight: var(--form-label-font-weight);
  color: var(--form-label-color);
  margin-bottom: -1px;
  display: flex !important;
  width: fit-content;

  .required {
    color: var(--form-field-required-icon);
    margin-left: 3px;
  }
}

.tooltip {
  position: relative;
  cursor: help;
  display: inline-block;

  span {
    font-size: 15px;
    margin-left: 5px;
  }
}

.tooltip .tooltip-text {
  visibility: hidden;
  z-index: 2;
  background-color: #fff;
  color: var(--text-color);
  background: var(--popover-background-color);
  border-radius: 15px;
  box-shadow: var(--popover-box-shadow);
  border-style: solid;
  border-color: var(--popover-border-color);
  border-width: var(--popover-border-width);
  padding: 10px 15px;
  top: 22px;
  width: 250px;
  cursor: default;

  position: absolute;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}
</style>
