<template>
  <div class="popover-holder" v-on-clickaway="close">
    <div class="popover-handler" ref="handle" @click="toggle()">
      <slot></slot>
    </div>

    <transition name="fade">
      <div class="popover-outer" v-show="showPopover" ref="actions">
        <slot name="content"></slot>
        <popover-inner
          v-if="this.$slots['content'] == null"
          :show.sync="showPopover"
          :actions="actions"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
import { createPopper } from "@popperjs/core";
import PopoverInner from "./PopoverInner";

export default {
  name: "VPopover",

  components: {
    PopoverInner,
  },

  mixins: [clickaway],

  props: {
    actions: {
      type: Array,
    },
    placement: {
      type: String,
      default: "bottom-start",
      validator(value) {
        return [
          "auto",
          "auto-start",
          "auto-end",
          "top",
          "top-start",
          "top-end",
          "bottom",
          "bottom-start",
          "bottom-end",
          "right",
          "right-start",
          "right-end",
          "left",
          "left-start",
          "left-end",
        ].includes(value);
      },
    },
    offset: {
      type: Array,
      default: () => {
        return [0, 0];
      },
    },
  },

  data() {
    return {
      showPopover: false,
      instance: null,
    };
  },

  methods: {
    close() {
      this.showPopover = false;
    },
    toggle() {
      this.showPopover = !this.showPopover;
      this.instance.update();
    },
  },

  mounted() {
    this.instance = createPopper(this.$refs.handle, this.$refs.actions, {
      placement: this.placement,
      modifiers: [
        {
          name: "offset",
          options: {
            offset: this.offset,
          },
        },
      ],
    });
  },
};
</script>

<style lang="scss" scoped>
.popover-holder {
  display: flex;
  width: fit-content;

  .popover-handler {
    display: flex;
  }
}

.popover-outer {
  z-index: 10;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
