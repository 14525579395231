<template>
  <div
    class="column"
    :style="
      'width: ' +
      (format === 'desktop' ? desktop : format === 'tablet' ? tablet : mobile) +
      '; padding: 0 ' +
      spacing +
      'px; display: flex; flex-direction: column; justify-content: ' +
      verticalAlign +
      ';'
    "
    :class="{
      'hide-tablet': tablet === 'hide',
      'hide-mobile': mobile === 'hide',
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "VRowItem",

  props: {
    desktop: {
      type: String,
      default: "50%",
    },
    tablet: {
      type: String,
      default: "100%",
    },
    mobile: {
      type: String,
      default: "100%",
    },
    verticalAlign: {
      type: String,
      default: "top",
    },
    spacing: {
      type: Number,
      default: 10,
    },
  },

  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },

  methods: {
    formatChange() {
      this.windowWidth = window.innerWidth;
    }
  },

  mounted() {
    window.addEventListener('resize', this.formatChange);
  },
  destroyed() {
    window.removeEventListener('resize', this.formatChange);
  },

  computed: {
    format() {
      return this.windowWidth > 1024
        ? "desktop"
        : this.windowWidth > 768
        ? "tablet"
        : "mobile";
    },
  },
};
</script>

<style lang="scss" scoped>
.column {
  padding: 0 10px;
  box-sizing: border-box;

  @media only screen and (max-width: 800px) {
    &.hide-tablet {
      display: none !important;
    }
  }
  @media only screen and (max-width: 800px) {
    &.hide-mobile {
      display: none !important;
    }
  }
}
</style>
