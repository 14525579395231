<template>
  <div class="button-holder" :class="align">
    <button
      :disabled="disabled || spinning"
      @click="click"
      :class="
        (spinning ? ' spinning' : '') +
        ((label == null || label === '') && icon != null ? ' icon-only' : '')
      "
    >
      <span
        class="material-icons-outlined icon-before"
        v-if="icon && iconBefore"
        >{{ icon }}</span
      >
      {{ label }}

      <span
        class="material-icons-outlined icon-after"
        v-if="icon && !iconBefore && !spinning"
        >{{ icon }}</span
      >

      <v-spinner
        class="button-spinner"
        color="var(--button-color)"
        v-if="spinning"
      />
    </button>
  </div>
</template>

<script>
import VSpinner from "@/components/global/Spinner";

export default {
  name: "VButton",

  components: {
    VSpinner
  },

  props: {
    icon: {
      type: String,
      default: null,
    },
    iconBefore: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: null,
    },
    spinning: {
      type: Boolean,
      default: false,
    },
    click: {
      type: Function,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    align: {
      type: String,
      default: "left",
      validator(value) {
        return ["left", "center", "right", "full"].includes(value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.button-holder {
  display: flex;
  width: auto;

  &.center {
    width: 100%;

    button {
      margin: 0 auto;
    }
  }
  &.right {
    width: 100%;

    button {
      margin-left: auto;
    }
  }
  &.full {
    width: 100%;

    button {
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
    }
  }

  button {
    background: var(--button-background-color);
    border-width: var(--button-border-width);
    border-style: solid;
    border-color: var(--button-border-color);
    box-shadow: var(--button-box-shadow);
    color: var(--button-color);
    font-family: var(--button-font-family);
    font-size: var(--button-font-size);
    font-weight: var(--button-font-weight);
    border-radius: var(--button-border-radius);
    padding: var(--button-padding);
    cursor: pointer;
    align-items: center;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    transition: transform 0.2s ease-in-out;

    display: flex;

    &:hover {
      transform: scale(1.02);
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      transform: scale(1);
    }

    /*
     * Spinner
     */
    .button-spinner {
      margin-left: 13px;
    }
    &.spinning {
      cursor: not-allowed;

      &:hover {
        transform: scale(1);
      }
    }

    /*
     * Icon
     */
    span {
      font-size: 18px;
      color: var(--button-icon-color);

      &.icon-before {
        margin-right: 8px;
      }
      &.icon-after {
        margin-left: 8px;
      }
    }
    &.icon-only {
      span {
        margin-right: 0;
      }
    }
  }
}
</style>
