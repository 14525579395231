<template>
  <div class="holder">
    <div class="inner">
      <h2 class="title">{{ $t("_word.contact_details") }}</h2>

      <div class="box">
        <v-row>
          <v-row-item>
            <v-input
              v-model="reservation.first_name"
              :required="true"
              :error="$v.reservation.first_name"
              :label="$t('_field.first_name')"
            />
          </v-row-item>
          <v-row-item>
            <v-input
              v-model="reservation.last_name"
              :required="true"
              :error="$v.reservation.last_name"
              :label="$t('_field.last_name')"
            />
          </v-row-item>
        </v-row>

        <v-row>
          <v-row-item>
            <v-input
              v-model="reservation.email"
              :required="true"
              :error="$v.reservation.email"
              :label="$t('_field.email')"
            />
          </v-row-item>
          <v-row-item>
            <v-input
              v-model="reservation.mobile_phone"
              :required="true"
              :error="$v.reservation.mobile_phone"
              :label="$t('_field.mobile_phone')"
            />
          </v-row-item>
        </v-row>

        <!--        <v-row>-->
        <!--          <v-row-item>-->
        <!--            <v-dropdown v-model="reservation.nationality" :label="$t('_field.nationality')" />-->
        <!--          </v-row-item>-->
        <!--        </v-row>-->

        <v-textarea
          v-model="reservation.remarks"
          :error="$v.reservation.remarks"
          :label="$t('_field.remarks')"
        />

        <v-checkbox
          v-model="reservation.terms"
          :error="$v.reservation.terms"
          :label="$t('_word.accept_the_terms')"
        />
      </div>

      <reservation-summary
        @update:grandTotal="
          (newTotal) => {
            total = newTotal;
          }
        "
        :reservation="reservation"
      >
        <div class="pay">
          <v-button
            align="full"
            icon="east"
            :icon-before="false"
            :spinning="isLoading"
            :click="reserve"
            :label="
              total > 0 ? $t('_button.confirm_and_pay') : $t('_button.confirm')
            "
          />

          <p class="payment-info" v-if="total > 0">
            {{ $t("_word.reservation_not_confirmed_until_payment") }}
          </p>
        </div>
      </reservation-summary>
    </div>
  </div>
</template>

<script>
import VButton from "@/components/form/Button";
import VInput from "@/components/form/Input";
import VRowItem from "@/components/row/RowItem";
import VRow from "@/components/row/Row";
import VDropdown from "@/components/form/Dropdown";
import VTextarea from "@/components/form/Textarea";
import ReservationSummary from "@/components/engine/parts/ReservationSummary";
import VCheckbox from "@/components/form/Checkbox";
import publicApi from "@/api/public";
const { required, maxLength, email } = require("vuelidate/lib/validators");

export default {
  components: {
    VCheckbox,
    ReservationSummary,
    VTextarea,
    VDropdown,
    VRow,
    VRowItem,
    VInput,
    VButton,
  },

  data() {
    return {
      isLoading: false,
      total: 0,
    };
  },

  validations: {
    reservation: {
      first_name: {
        required,
        maxLength: maxLength(45),
      },
      last_name: {
        required,
        maxLength: maxLength(90),
      },
      email: {
        required,
        email,
        maxLength: maxLength(255),
      },
      mobile_phone: {
        required,
        maxLength: maxLength(16),
      },
      remarks: {
        maxLength: maxLength(2000),
      },
      terms: {
        accepted: function (val) {
          return this.reservation.terms;
        },
      },
    },
  },

  props: {
    activeStep: {},
    rooms: {},
    reservation: {},
    goStep: {
      type: Function,
    },
  },

  methods: {
    reserve() {
      this.$v.$touch();

      if (!this.reservation.terms) {
        this.$popcorn("error", this.$t("_error.terms_not_accepted"));
        return;
      }

      if (this.$v.$invalid) {
        this.$popcorn("error", this.$t("_error.not_all_fields_are_correct"));
        return;
      }

      this.isLoading = true;

      this.$v.$reset();

      this.reservation.language = this.$i18n.locale;

      publicApi
        .post("reservation", {
          ...this.reservation,
          coupon_code: this.reservation.coupon
            ? this.reservation.coupon.code
            : null,
        })
        .then((res) => {

          let url = '';
          if(res.data.status === 'completed') {
            url = 'https://slapendehollander.nl/bedankt-voor-uw-reservering?first_name=' + encodeURI(this.reservation.first_name) + '&period=' + encodeURI(this.formatDate(this.reservation.start_date) + ' tot ' + this.formatDate(this.reservation.end_date));
          }
          else {
            url = res.data.payment_url;
          }

          if (window.self !== window.top) {
            window.parent.location.href = url;
          } else {
            window.location.href = url;
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.holder {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  justify-content: center;
  box-sizing: border-box;
  overflow: auto;
  padding: 0 20px;

  .inner {
    padding: 50px 0 100px 0;
    height: fit-content;
    width: 100%;
    max-width: 1000px;
    box-sizing: border-box;

    .title {
      font-size: 27px;
      margin-bottom: 6px;
      text-align: center;
    }

    .box {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 30px;
      background: var(--card-background-color);
      border-radius: var(--card-border-radius);
      box-shadow: var(--card-box-shadow);
      padding: 30px;
    }

    .pay {
      padding: 20px 30px 30px 30px;
    }
    .payment-info {
      margin-top: 5px;
      font-size: 15px;
    }
  }
}
</style>
