var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"holder"},[_c('div',{staticClass:"inner"},[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("_word.summary")))]),_c('div',{staticClass:"rooms-holder"},[_vm._l((_vm.reservation.rooms),function(room){return _c('div',{staticClass:"room"},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":room.data.images.length == 0
                ? 'assets/default.jpg'
                : room.data.images[0].url}})]),_c('div',{staticClass:"info"},[_c('h2',[_vm._v(_vm._s(room.data.name))]),_c('div',{staticClass:"price-holder mobile"},[_c('div',{staticClass:"quantity"},[_vm._v(_vm._s(room.number_of_rooms)+"x")]),_c('div',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm.formatPrice(room.total_price))+" ")])]),_c('div',{staticClass:"date"},[_c('span',{staticClass:"material-icons"},[_vm._v("calendar_month")]),_vm._v(" "+_vm._s(_vm.formatDate(_vm.reservation.start_date) + " - " + _vm.formatDate(_vm.reservation.end_date))+" ")]),_c('div',{staticClass:"rooms"},[_c('span',{staticClass:"material-icons"},[_vm._v("bed")]),_vm._v(" "+_vm._s(_vm.$tc("_word.x_rooms", room.number_of_rooms, { rooms: room.number_of_rooms, }))+" ")]),_c('div',{staticClass:"guests"},[_c('span',{staticClass:"material-icons"},[_vm._v("person_outline")]),_vm._v(" "+_vm._s(_vm.$tc("_word.x_guests", room.number_of_guests, { guests: room.number_of_guests, }))+" ")])]),_c('div',{staticClass:"price-holder desktop"},[_c('div',{staticClass:"quantity"},[_vm._v(_vm._s(room.number_of_rooms)+"x")]),_c('div',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm.formatPrice(room.total_price))+" ")])]),_c('div',{staticClass:"actions"},[_c('div',{staticClass:"edit-button",on:{"click":() => {
                _vm.editRoom(room);
              }}},[_c('span',{staticClass:"material-icons"},[_vm._v("edit")]),_vm._v(" "+_vm._s(_vm.$t("_button.edit"))+" ")]),_c('div',{staticClass:"delete-button",on:{"click":() => {
                _vm.deleteRoom(room);
              }}},[_c('span',{staticClass:"material-icons"},[_vm._v("clear")])])])])}),_c('div',{staticClass:"add-extra",on:{"click":() => {
            _vm.goStep(2);
          }}},[_c('div',{staticClass:"add-extra-button"},[_c('span',{staticClass:"material-icons"},[_vm._v("add")]),_vm._v(" "+_vm._s(_vm.$t("_button.add_another_room"))+" ")])])],2),_c('reservation-summary',{attrs:{"reservation":_vm.reservation}},[_c('div',{staticClass:"next"},[_c('v-button',{attrs:{"align":"full","icon":"east","icon-before":false,"click":_vm.next,"label":_vm.$t('_button.next')}})],1)])],1),_c('room-modal',{attrs:{"reservation":_vm.reservation,"room":_vm.selectedRoom,"next":() => {}},model:{value:(_vm.showRoomModal),callback:function ($$v) {_vm.showRoomModal=$$v},expression:"showRoomModal"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }