<template>
  <div class="holder">
    <div class="inner">
      <h2 class="title">{{ $t("_word.summary") }}</h2>

      <div class="rooms-holder">
        <div class="room" v-for="room in reservation.rooms">
          <div class="image">
            <img
              :src="
                room.data.images.length == 0
                  ? 'assets/default.jpg'
                  : room.data.images[0].url
              "
            />
          </div>
          <div class="info">
            <h2>{{ room.data.name }}</h2>

            <div class="price-holder mobile">
              <div class="quantity">{{ room.number_of_rooms }}x</div>
              <div class="price">
                {{ formatPrice(room.total_price) }}
              </div>
            </div>

            <div class="date">
              <span class="material-icons">calendar_month</span>
              {{
                formatDate(reservation.start_date) +
                " - " +
                formatDate(reservation.end_date)
              }}
            </div>

            <div class="rooms">
              <span class="material-icons">bed</span>
              {{
                $tc("_word.x_rooms", room.number_of_rooms, {
                  rooms: room.number_of_rooms,
                })
              }}
            </div>

            <div class="guests">
              <span class="material-icons">person_outline</span>
              {{
                $tc("_word.x_guests", room.number_of_guests, {
                  guests: room.number_of_guests,
                })
              }}
            </div>
          </div>

          <div class="price-holder desktop">
            <div class="quantity">{{ room.number_of_rooms }}x</div>
            <div class="price">
              {{ formatPrice(room.total_price) }}
            </div>
          </div>

          <div class="actions">
            <div
              class="edit-button"
              @click="
                () => {
                  editRoom(room);
                }
              "
            >
              <span class="material-icons">edit</span>
              {{ $t("_button.edit") }}
            </div>
            <div
              class="delete-button"
              @click="
                () => {
                  deleteRoom(room);
                }
              "
            >
              <span class="material-icons">clear</span>
            </div>
          </div>
        </div>

        <div
          class="add-extra"
          @click="
            () => {
              goStep(2);
            }
          "
        >
          <div class="add-extra-button">
            <span class="material-icons">add</span>
            {{ $t("_button.add_another_room") }}
          </div>
        </div>
      </div>

      <reservation-summary :reservation="reservation">
        <div class="next">
          <v-button align="full" icon="east" :icon-before="false" :click="next" :label="$t('_button.next')" />
        </div>
      </reservation-summary>
    </div>

    <room-modal
      :reservation="reservation"
      :room="selectedRoom"
      :next="() => {}"
      v-model="showRoomModal"
    />
  </div>
</template>

<script>
import VButton from "@/components/form/Button";
import ReservationSummary from "@/components/engine/parts/ReservationSummary";
import RoomModal from "@/components/engine/modal/Room";

export default {
  components: {
    RoomModal,
    ReservationSummary,
    VButton,
  },

  data() {
    return {
      showRoomModal: false,
      selectedRoom: null,
    };
  },

  props: {
    activeStep: {},
    next: {
      type: Function,
    },
    rooms: {},
    reservation: {},
    goStep: {
      type: Function,
    },
  },

  methods: {
    editRoom(room) {
      this.selectedRoom = room.data;
      this.showRoomModal = true;
    },
    deleteRoom(room) {
      this.reservation.rooms.splice(this.reservation.rooms.findIndex((it) => {
        return it.id === room.id;
      }), 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.holder {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  justify-content: center;
  box-sizing: border-box;
  overflow: auto;
  padding: 0 20px;

  .inner {
    padding: 50px 0 100px 0;
    height: fit-content;
    width: 100%;
    max-width: 1000px;
    box-sizing: border-box;

    .title {
      font-size: 27px;
      margin-bottom: 6px;
      text-align: center;
    }

    .rooms-holder {
      margin-top: 40px;

      .room {
        display: flex;
        flex-direction: row;
        width: 100%;
        background: var(--card-background-color);
        border-radius: var(--card-border-radius);
        box-shadow: var(--card-box-shadow);
        padding: 15px;
        position: relative;
        box-sizing: border-box;
        margin-bottom: 20px;

        .image {
          width: 300px;

          img {
            width: 100%;
            border-radius: 5px;
            height: 190px;
            object-fit: cover;
          }
        }

        .info {
          flex: 1;
          padding: 10px 40px;
          display: flex;
          flex-direction: column;

          h2 {
            font-size: 22px;
            font-weight: 500;
          }

          .date,
          .rooms,
          .guests {
            font-size: 17px;
            display: flex;
            margin-bottom: 10px;
            align-items: center;
            color: #888;

            span {
              color: var(--primary-color);
              margin-right: 10px;
              font-size: 25px;
            }
          }
        }

        .price-holder {
          position: absolute;
          right: 30px;
          top: 25px;
          display: flex;
          flex-direction: row;
          align-items: flex-end;

          &.mobile {
            display: none;
          }

          .quantity {
            margin-right: 13px;
            font-size: 20px;
            color: #888;
          }
          .price {
            font-size: 24px;
            font-weight: 500;
          }
        }

        .actions {
          position: absolute;
          right: 25px;
          bottom: 20px;
          display: flex;
          justify-content: center;

          .edit-button {
            display: flex;
            align-items: center;
            color: var(--primary-color);
            cursor: pointer;
            padding: 7px 15px;
            border-radius: 30px;
            margin-right: -2px;
            transition: background-color 0.3s;

            span {
              position: relative;
              margin-right: 10px;
              font-size: 20px;
            }

            &:hover {
              background: #fafafa;
            }
          }

          .delete-button {
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--primary-color);
            cursor: pointer;
            padding: 7px;
            border-radius: 30px;
            transition: background-color 0.3s;

            span {
              position: relative;
              font-size: 23px;
              bottom: -1px;
            }

            &:hover {
              background: #fafafa;
            }
          }
        }
      }
    }

    .add-extra {
      display: flex;
      justify-content: center;
      margin-top: 25px;
      margin-bottom: 60px;

      .add-extra-button {
        display: flex;
        align-items: center;
        color: #888;
        cursor: pointer;
        padding: 8px 20px;
        border-radius: 30px;
        font-weight: 500;
        transition: background-color 0.3s;

        span {
          position: relative;
          margin-right: 10px;
          font-size: 20px;
        }

        &:hover {
          background: #f3f3f3;
        }
      }
    }

    .next {
      padding: 20px 30px 30px 30px;
    }
  }

  @media only screen and (max-width: 900px) {

    .inner {

      .rooms-holder {

        .room {

          .price-holder.mobile {
            position: relative;
            display: flex;
            top: 0;
            right: 0;
            margin: -15px 0 20px 0;
            flex-direction: row;
            align-items: flex-end;

            .price {
              font-size: 20px;
            }

            .quantity {
              font-size: 18px;
              margin-right: 10px;
            }
          }

          .price-holder.desktop {
            display: none;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 800px) {

    .inner {

      .rooms-holder {

        .room {

          .info {
            padding: 15px 20px;
          }

          .image {

            display: none;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 800px) {

    .inner {

      .rooms-holder {

        .room {

          .info {
            padding: 10px 15px 50px 15px;
          }

          .image {

            display: none;
          }
        }
      }
    }
  }
}
</style>
