<template>
  <div class="row-wrapper">
    <div
      class="row"
      :class="{ 'mobile-revert': mobileRevert }"
      :style="
        spacing != null
          ? 'margin-left: -' +
            spacing +
            'px; width: calc(100% + ' +
            spacing * 2 +
            'px);'
          : ''
      "
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "VRow",
  props: {
    spacing: {
      type: Number,
      default: 10,
    },
    mobileRevert: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.row-wrapper {
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  .row {
    display: flex;
    flex-wrap: wrap;

    @media only screen and (max-width: 800px) {
      &.mobile-revert {
        flex-direction: column-reverse;
      }
    }
  }
}
</style>
