var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column",class:{
    'hide-tablet': _vm.tablet === 'hide',
    'hide-mobile': _vm.mobile === 'hide',
  },style:('width: ' +
    (_vm.format === 'desktop' ? _vm.desktop : _vm.format === 'tablet' ? _vm.tablet : _vm.mobile) +
    '; padding: 0 ' +
    _vm.spacing +
    'px; display: flex; flex-direction: column; justify-content: ' +
    _vm.verticalAlign +
    ';')},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }