<template>
  <div
    class="dropdown-holder"
    :key="componentVersion"
    v-observe-visibility="firstView"
  >
    <v-field-label :required="required" :tooltip="tooltip" :label="label" />

    <v-select
      type="text"
      append-to-body
      :placeholder="placeholder || $t('_word.choose')"
      v-model="selected"
      :options="realValues"
      :reduce="realValuesReduce"
      :disabled="disabled"
      :label="(parseTranslation ? '_' : '') + valuesLabel"
      :multiple="multiple"
      :calculate-position="withPopper"
      :components="{ OpenIndicator, Deselect }"
      :filterable="!ajax"
      @search="search"
      @close="close"
      :class="{
        error: error != null && error.$error,
        'is-multi-select': multiple,
      }"
      :clearable="clearable"
    >
      <template #no-options="{ search, searching, loading }">
        <div class="dropdown-no-results" v-if="!ajaxHasNextPage">
          <span class="vs__no-options-inner">{{
            noResultsText || $t("_word.no_results_found") + "..."
          }}</span>
          <v-button
            v-if="noResultsButton != null"
            class="dropdown-no-results-button btn-transparent"
            :icon="
              noResultsButton.icon == null
                ? 'add_circle_outline'
                : noResultsButton.icon
            "
            :label="noResultsButton.label"
            :click="noResultsButton.onClick"
          />
        </div>
        <span v-else></span>
      </template>

      <template #list-footer v-if="ajax != null">
        <div
          v-if="ajaxHasNextPage"
          class="dropdown-load-more-spinner"
          v-observe-visibility="ajaxVisibility"
        >
          <v-spinner v-show="ajaxHasNextPage" />
        </div>
      </template>
    </v-select>
    <div
      class="dropdown-error"
      v-if="error.$error && error.required != null && !error.required"
    >
      {{ $t("_error.field_required_short") }}
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { createPopper } from "@popperjs/core";
import VFieldLabel from "@/components/form/FieldLabel";
import VButton from "@/components/form/Button";
import VSpinner from "@/components/global/Spinner";
import OpenIndicator from "./Dropdown/OpenIndicator"
import Deselect from "./Dropdown/Deselect"

export default {
  name: "VDropdown",

  components: {
    VSpinner,
    VButton,
    VFieldLabel,
    OpenIndicator,
    Deselect
  },

  props: {
    label: {
      type: String,
      default: null,
    },
    tooltip: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      required: true,
    },
    values: {
      type: Array,
      default: () => {
        return [];
      },
    },
    valuesReduce: {
      type: Function,
      default: (option) => option,
    },
    valuesLabel: {
      type: String,
      default: "label",
    },
    placeholder: {
      type: String,
    },
    noResultsText: {
      type: String,
    },
    noResultsButton: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    error: {
      type: Object,
      default: () => {
        return {};
      },
    },
    translate: {
      type: Boolean,
      default: false,
    },
    parseTranslation: {
      type: Boolean,
      default: false,
    },
    translatePrefix: {
      type: String,
      default: "",
    },
    translateSuffix: {
      type: String,
      default: "",
    },
    ajax: {
      type: Object,
    },
  },

  data() {
    return {
      componentVersion: 0,
      selected: null,

      ajaxValues: [],
      ajaxPage: 1,
      ajaxMaxPage: 2,
      ajaxBottom: false,
      ajaxBusy: false,
      ajaxLoaded: false,

      loaded: false,

      opened: false,

      OpenIndicator: OpenIndicator,
      Deselect: Deselect,

      currentSearch: "",
      ajaxSearchRequest: null,
    };
  },

  created() {
    this.selected = this.value;

    if (this.parseTranslation && this.selected != null) {
      if (!this.multiple) {
        if (typeof this.selected === "object") {
          this.selected["_" + this.valuesLabel] = this.getTranslation(
            this.selected[this.valuesLabel]
          );
        }
      } else {
        this.selected.forEach((option) => {
          option["_" + this.valuesLabel] = this.getTranslation(
            option[this.valuesLabel]
          );
        });
      }
    }

    this.componentVersion++;
    this.$nextTick(() => {
      this.loaded = true;
    });
  },

  methods: {
    firstView(isVisible) {
      if (isVisible && !this.ajaxLoaded) {
        this.ajaxFetch();
        this.ajaxBottom = false;
      }
    },

    search(search) {
      if (this.ajaxSearchRequest != null) clearTimeout(this.ajaxSearchRequest);

      this.ajaxSearchRequest = setTimeout(() => {
        this.currentSearch = search;
        this.ajaxSearchRequest = null;

        this.ajaxPage = 1;
        this.ajaxLoaded = false;
        this.ajaxValues = [];

        this.ajaxFetch();
      }, 300);
    },

    close() {
      this.ajaxBottom = false;
    },

    ajaxVisibility(isVisible) {
      this.ajaxBottom = isVisible;
      this.ajaxFetch();
    },

    ajaxFetch() {
      if (this.ajax == null) return;

      if (
        this.ajaxLoaded &&
        (this.ajax.paginate == null || !this.ajax.paginate)
      )
        return;

      if (!this.ajaxHasNextPage) return;

      if (this.ajaxBottom && !this.ajaxBusy) {
        this.ajaxBusy = true;

        let api = this.$ut.apis[this.ajax.api];

        // search
        let query = "search=" + this.currentSearch + "&";

        // parameters
        if (this.ajax.parameters != null) {
          const keys = Object.keys(this.ajax.parameters);
          for (const key of keys) {
            query +=
              key +
              "=" +
              (Array.isArray(this.ajax.parameters[key])
                ? this.ajax.parameters[key].join()
                : encodeURIComponent(this.ajax.parameters[key])) +
              "&";
          }
        }

        // pages
        if (this.ajax.paginate != null && this.ajax.paginate) {
          query += "page=" + this.ajaxPage + "&";
        }

        query = query === "" ? "" : "?" + query.substring(0, query.length - 1);

        api.get(this.ajax.endpoint + query).then((result) => {
          let letBaseData = [];
          if (this.ajax.paginate == null || !this.ajax.paginate) {
            letBaseData = result.data;
          } else {
            letBaseData = result.data.data;
            this.ajaxMaxPage = result.data.meta.last_page;
          }
          let data = letBaseData;
          if (this.ajax.filter != null) {
            data = data.filter(this.ajax.filter);
          }
          if (this.ajax.format != null) {
            data.forEach((item) => {
              this.ajax.format(item);
            });
          }
          this.ajaxLoaded = true;
          this.ajaxBusy = false;
          this.ajaxPage++;
          this.ajaxValues = [...this.ajaxValues, ...data];

          if (letBaseData.length > 0) {
            Vue.nextTick(() => {
              setTimeout(() => {
                if (this.ajaxBottom) {
                  this.ajaxFetch();
                }
              }, 50);
            });
          }
        });
      }
    },

    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width;

      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: "bottom",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 0],
            },
          },
          {
            name: "toggleClass",
            enabled: true,
            phase: "write",
            fn({ state }) {
              if (state.placement === "top")
                component.$el.classList.add("placement-top");
              else component.$el.classList.remove("placement-top");
            },
          },
        ],
      });

      return () => popper.destroy();
    },
  },

  computed: {
    ajaxHasNextPage() {
      if (this.ajax == null) return false;

      if (
        !this.ajaxLoaded ||
        (this.ajax.paginate != null && this.ajax.paginate)
      ) {
        if (this.ajaxPage <= this.ajaxMaxPage) return true;
      }
      return false;
    },

    realValues() {
      if (this.ajax != null && !this.parseTranslation) {
        return this.ajaxValues;
      }

      if (this.translate) {
        let output = [];
        this.values.forEach((value) => {
          output.push({
            label: this.$t(
              this.translatePrefix +
                (value + "").replaceAll("-", "_") +
                this.translateSuffix
            ),
            id: value,
          });
        });

        return output;
      }

      if (this.parseTranslation) {
        let output = [];
        (this.ajax != null ? this.ajaxValues : this.values).forEach((value) => {
          output.push({
            ...value,
            ["_" + this.valuesLabel]: this.getTranslation(
              value[this.valuesLabel]
            ),
          });
        });

        return output;
      }

      return this.values;
    },

    realValuesReduce() {
      if (this.translate) {
        return (option) => option.id;
      }

      return this.valuesReduce;
    },
  },

  watch: {
    selected(val) {
      if (this.loaded) {
        this.$emit("input", val);
      }
    },
    value(val) {
      this.selected = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.form_input {
  margin-bottom: 17px;
  width: 100%;
}

.dropdown-holder {
  position: relative;
  margin-bottom: 15px;
  width: 100%;

  .dropdown-error {
    color: var(--form-field-error-color);
    letter-spacing: var(--form-field-error-letter-spacing);
    font-size: var(--form-field-error-font-size);
    font-weight: var(--form-field-error-font-weight);
    position: absolute;
    right: 0;
    bottom: -18px;
    text-align: right;
  }
}

.dropdown-no-results {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
}
</style>

<style lang="scss">
.dropdown-holder {
  .vs__dropdown-toggle {
    border-radius: var(--form-field-border-radius);
    border-style: solid;
    border-width: var(--form-field-border-width);
    border-color: var(--form-field-border-color);
    background: var(--form-field-background-color);
    padding: var(--form-field-input-padding);
    box-shadow: var(--form-field-box-shadow);
    color: var(--form-field-color);
    font-size: var(--form-field-font-size);
    font-weight: var(--form-field-font-weight);
    margin-top: 7px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    transition: all 0.2s;
    min-height: var(--form-field-min-height);
  }

  .vs--disabled .vs__dropdown-toggle {
    background: var(--form-field-disabled-background-color);
    color: var(--form-field-disabled-color);
  }

  .error .vs__dropdown-toggle {
    border-color: var(--form-field-error-border-color);
    background: var(--form-field-error-background-color);
  }

  .vs__search {
    padding: 9px 0 !important;
    margin-top: 0 !important;
    font-weight: 400;
    position: absolute;
    width: 100%;
  }
  .is-multi-select {
    .vs__search {
      position: relative;
      width: unset;
    }
  }
  //.vs--open .vs__search {
  //  display: block;
  //}

  .vs__selected-options {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    align-items: center;
  }

  .vs__selected {
    padding: 0 !important;
    margin: 0 !important;
    top: 0;
    bottom: 0;
  }

  div[role="combobox"] .vs__selected {
    padding: 0 !important;
    margin: 8.5px 18px 8.5px 0 !important;
    min-height: 25px;
    justify-content: center;
    background: none !important;
    border: none !important;
  }

  .is-multi-select div[role="combobox"] .vs__selected {
    border-style: solid !important;
    border-color: var(--form-dropdown-multi-item-border-color) !important;
    border-width: var(--form-dropdown-multi-item-border-width) !important;
    box-shadow: var(--form-dropdown-multi-item-box-shadow);
    padding: 5px 13px 4px 13px !important;
    margin: 7px 8px 7px 0 !important;
    border-radius: var(--form-dropdown-multi-item-border-radius);
  }

  .vs__dropdown-toggle {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    &[aria-expanded="true"] {
      border-bottom-right-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }

  .vs__selected-options input {
    margin-top: 4px;
  }

  .vs__selected-options input::placeholder {
    color: var(--form-field-placeholder-color);
  }

  .vs__actions {
    padding-right: 0;
  }

  .vs--disabled .vs__search,
  .vs--disabled .vs__open-indicator {
    background: transparent !important;
    font-weight: 400;
    cursor: default;
  }
  .vs--disabled .vs__clear {
    display: none;
  }

  .vs--disabled .vs__selected {
    color: var(--form-field-disabled-color);
  }

  .vs__clear {
    margin-right: 1px;
  }

  .vs__deselect {
    margin-left: 2px !important;
  }

  .vs__clear span,
  .vs__deselect span {
    width: 20px;
    height: 20px;
    font-size: 21px;
    color: var(--form-dropdown-icon-color) !important;
  }
}

.dropdown-load-more-spinner {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: -4px;
  padding: 15px 0;
}

.dropdown-holder .vs__open-indicator {
  width: 20px;
  height: 20px;
  margin-bottom: 5px;
  transition: none !important;
  transform: none !important;
  margin-top: -2px;
  color: var(--form-dropdown-icon-color) !important;
}

.vs__dropdown-menu {
  margin-top: -2px !important;
  border-style: solid;
  border-width: var(--form-field-border-width);
  border-color: var(--form-field-border-color) !important;
  box-shadow: var(--form-field-box-shadow) !important;
  width: 100%;
  z-index: 999999 !important;
  border-radius: 0 0 var(--form-field-border-radius)
    var(--form-field-border-radius);
}
.vs__no-options {
  color: var(--text-color);
  font-size: var(--text-font-size);
  font-weight: var(--text-font-weight);

  .vs__no-options-inner {
    padding: 11px 0;
  }
}

.vs__dropdown-menu[data-popper-placement="top"] {
  border-radius: var(--form-field-border-radius) var(--form-field-border-radius)
    0 0 !important;
  margin-bottom: -2px !important;
}
.v-select.placement-top .vs__dropdown-toggle[aria-expanded="true"] {
  border-width: var(--form-field-border-width);
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.vs__dropdown-option {
  padding: 9px 20px !important;
}
.vs__dropdown-option--highlight {
  background: var(--form-dropdown-item-hover-background) !important;
  color: var(--form-dropdown-item-hover-color) !important;
}
</style>
