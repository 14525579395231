<template>
  <portal to="modals">
    <div :key="uid">
      <transition name="fade">
        <div class="mask" v-show="show && focus">
          <div
            class="modal"
            :class="size"
          >
            <div class="modal-inner">
              <slot></slot>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </portal>
</template>

<script>

export default {
  name: "VModal",

  data() {
    return {
      uid: null,
      focus: true,
      allowClose: false,
    };
  },

  mounted() {
    this.uid = this._uid;
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
      default: () => {
        return () => {};
      },
    },
    size: {
      type: String,
      default: "n",
      validator(value) {
        return ["n", "l", "xl", "xxl"].includes(value);
      },
    }
  },

  methods: {
    close() {
      if (this.allowClose) {
        this.$emit("update:show", false);
        this.onClose();
      }
    },
  },

  watch: {
    show() {
      if (this.show) {
        if (this.$root.openModals == null) {
          this.$root.openModals = [];
        }

        let last = this.$root.openModals.slice(-1);
        if (last.length > 0) {
          last[0].focus = false;
        }

        this.$root.openModals.push(this);

        document.documentElement.style.overflow = "hidden";

        this.allowClose = false;
        setTimeout(() => {
          this.allowClose = true;
        }, 1);

      }

      if (!this.show) {
        this.$root.openModals.pop()

        let last = this.$root.openModals.slice(-1);
        if (last.length > 0) {
          last[0].focus = true;
        }
        else {
          document.documentElement.style.overflow = "auto";
        }
      }
    },
  },

  destroyed() {
    document.documentElement.style.overflow = "auto";
  },
};
</script>

<style lang="scss" scoped>
.mask {
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    position: absolute;

    background: var(--modal-background-color);
    border-radius: 15px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.05);
    width: 500px;
    max-height: 90vh;
    overflow: hidden;
    padding: 12px 0;

    .modal-inner {
      overflow-y: auto;
      height: inherit;
      max-height: inherit;
    }

    &.l {
      width: 650px;

      @media only screen and (max-width: 690px) {
        & {
          width: calc(100% - 40px);
          margin: 0 20px;
        }
      }
    }
    &.xl {
      width: 1000px;

      @media only screen and (max-width: 1040px) {
        & {
          width: calc(100% - 40px);
          margin: 0 20px;
        }
      }
    }

    &.xxl {
      width: 1200px;

      @media only screen and (max-width: 1240px) {
        & {
          width: calc(100% - 40px);
          margin: 0 20px;
        }
      }
    }

    @media only screen and (max-width: 500px) {
      & {
        max-height: unset;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        border-radius: 0;
        width: 100% !important;
        margin: 0 !important;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active .modal {
  animation: openCloseModal 0.3s;
  -webkit-animation: openCloseModal 0.3s;
}

@keyframes openCloseModal {
  0% {
    transform: scale(0.97);
  }
  100% {
    transform: scale(1);
  }
}
</style>
